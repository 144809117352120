<div
  [style.backgroundImage]="backgroundImage"
  [style.backgroundColor]="backgroundColor"
  [style.color]="foreground"
  class="container flex-column leitor-content-placeholder"
>
  <app-navbar class="navbar-container"></app-navbar>
  <div class="relative flex-1">
    <app-leitor-content></app-leitor-content>
  </div>
  <div class="statusbar-placeholder" *ngIf="configuracoes">
    <app-leitor-statusbar></app-leitor-statusbar>
  </div>
</div>

<div
  class="painel-lateral-timer container"
  *ngIf="MostrarPainelLateral"
  (click)="painelLateral_dismiss()"
>
  <div class="wrapper shadow" (click)="$event.stopPropagation()">
    <app-painel-lateral></app-painel-lateral>
  </div>
</div>
