<div class="placeholder flex-row center-center">
  <div class="flex-row center-center" *ngIf="apontamentoAtual">
    <div class="wrapper-btn-play-pause">
      <button mat-icon-button (click)="toggleTimer()">
        <mat-icon svgIcon="timer-ativo" *ngIf="apontamentoAtivo"></mat-icon>
        <mat-icon svgIcon="timer-parado" *ngIf="!apontamentoAtivo"></mat-icon>
      </button>
    </div>
    <div class="texto-tempo">{{ tempoApontamento }}</div>
    <div>
      <input
        #txtAnotarTimer
        class="txt txt-anotar-timer"
        type="text"
        [placeholder]="textoApontamentoAtual || 'Anote aqui...'"
        [(ngModel)]="textoAnotacao"
        (keyup.enter)="inserirAnotacao()"
        name="txtTimerInput"
        id="txtTimerInput"
      />
    </div>
  </div>
  <div class="flex-row center-center">
    <span
      class="icon icon-menu clickable"
      title="Detalhes"
      (click)="togglePainel()"
    ></span>
  </div>
</div>
