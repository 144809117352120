<div class="statusbar-wrapper flex-row center-start background-primary">
  <div
    class="flex-row center-center status-icons"
    *ngIf="(appOffline$ | async) || pendingChanges > 0"
  >
    <mat-icon
      svgIcon="offline"
      *ngIf="appOffline$ | async"
      matTooltip="Não foi possível se conectar ao servidor, tentaremos automaticamente mais tarde"
    >
    </mat-icon>
    <mat-icon
      svgIcon="pendente"
      *ngIf="pendingChanges > 0"
      matTooltip="Existem informações pendentes para sincronizar com o servidor"
    >
    </mat-icon>
  </div>

  <app-info-panel></app-info-panel>
  <div class="flex-1"></div>
  <controle-fonte
    *ngIf="(conteudo | async) && !(conteudo | async).novaGuia"
  ></controle-fonte>
  <div class="spacer" style="width: 16px"></div>
  <app-indicador-leitura
    class="placeholder-indicador-leitura flex-row"
    *ngIf="progresso || tempoRestante"
    [progresso]="progresso"
    [tempoRestante]="tempoRestante"
  ></app-indicador-leitura>
</div>
