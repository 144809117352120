import { environment } from "../environments/environment";

export class AppConfig {
  static apiEndpoint = environment.serverUrl;
  static logApiEndpoint = environment.serverLogUrl;

  static versaoIndexDB = 6;
  static key = "?%JyWMU*_6UcS8r#";

  static freshdeskEndpoint = "https://supervademecum.freshdesk.com/api/v2/";
  static freshdeskKey64Based = "ck45cTJhN3NhdDJCdWNBOVBnRTp4";

  static larguraIndicadorPopup = "260px";
  static larguraSyncPopup = "420px";
  static alturaSyncPopup = "144px";

  static TempoReconnect = 30;
  static tamanhoPagina = 20;
}
