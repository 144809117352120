<!-- <span [style.cursor]="cursor" [style.backgroundColor]="trechoTexto.backgroundColor" [style.textDecoration]="trechoTexto.textDecoration" (click)="processClick($event)">
{{trechoTexto.texto}}
</span> -->
<span
  *ngIf="!tabela"
  class="fonte-leitura"
  [ngClass]="{ 'realce-busca': trechoTexto.realceBusca }"
  [style.backgroundColor]="trechoTexto.backgroundColor"
  [style.textDecoration]="trechoTexto.textDecoration"
  (click)="processClick($event)"
  ><!--
-->{{ trechoTexto.texto
  }}<!--
--></span>
<div class="fonte-leitura" *ngIf="contemTabela && tabela" class="tbl noselect">
  <table class="table-striped table-bordered rounded" style="width: 100%">
    <tr class="row" *ngFor="let linha of tabela.linhas">
      <div #Conteiner class="tbl-container">
        <span
          *ngFor="let coluna of linha.colunas"
          class="border border-primary"
          [style.width]="coluna.width"
          style="padding: 4px"
          >{{ coluna.texto }}</span
        >
      </div>
    </tr>
  </table>
</div>
