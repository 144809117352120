import { Component, OnInit, OnDestroy } from "@angular/core";
import { ConteudoService } from "../../services/conteudo.service";
import { Conteudo } from "../../models/pagina/conteudo";
import { OpcaoToolbar } from "./opcaoToolbar";
import {
  Selection,
  SelectionService,
  ModoSelecao,
} from "../../services/selection.service";
import { BuscaService } from "../../services/busca.service";
import { Subscription } from "rxjs";
import { UsuarioPreferenciasService } from "../../services/data-services/usuario.preferencias.service";
import { ConfiguracoesUsuario } from "../../models/usuario/ConfiguracoesUsuario";
import { BuscaPanelParameters } from "../leitor-content-panelbusca/busca-panel.parameters";

@Component({
  selector: "app-leitor-toolbar",
  templateUrl: "./leitor-toolbar.component.html",
  styleUrls: ["./leitor-toolbar.component.scss"],
})
export class LeitorToolbarComponent implements OnInit, OnDestroy {
  public visivel = false;

  public opcoes: OpcaoToolbar[];
  public opcoesExibir: OpcaoToolbar[];
  public opcaoSelecionada: OpcaoToolbar;
  public selection: Selection;
  public painelBuscaAberto = false;

  private subscriptions: Subscription[] = [];
  modoSelecao: boolean;

  constructor(
    private conteudoService: ConteudoService,
    private buscaService: BuscaService,
    private selectionService: SelectionService,
    private usuarioPreferenciasService: UsuarioPreferenciasService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.conteudoService.Conteudo.subscribe((c) => this.conteudo_subscribe(c))
    );
    this.subscriptions.push(
      this.usuarioPreferenciasService.$Configuracoes.subscribe((config) =>
        this.config_subscribe(config)
      )
    );
    this.subscriptions.push(
      this.selectionService.$Selection.subscribe((s) => {
        this.modoSelecao = s.modo === ModoSelecao.Padrao;
      })
    );

    this.subscriptions.push(
      this.buscaService.getBusca().subscribe((c) => this.busca_changed(c))
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = [];
  }
  private busca_changed(busca: BuscaPanelParameters): void {
    this.painelBuscaAberto = busca ? true : false;
  }

  private conteudo_subscribe(conteudo: Conteudo): void {
    if (conteudo) {
      this.visivel =
        conteudo.linhas &&
        conteudo.linhas.length > 0 &&
        (conteudo.idLei ? true : false);
    }
  }

  private config_subscribe(config: ConfiguracoesUsuario) {
    if (config) {
      this.opcoes = OpcaoToolbar.CarregarItensMenu(
        config.parametrosCaneta
      ).filter((o) => o.toolbar);
      // this.opcoesExibir = this.opcoes.filter(o => o.descricao !== 'Seleção');
      this.opcoesExibir = this.opcoes;

      if (!this.opcaoSelecionada) {
        this.opcaoSelecionada = this.opcoes[0];
        this.opcaoSelecionada.ativo = true;

        this.selection = new Selection();
        this.selection.modo = this.opcaoSelecionada.modo;
        this.selection.tipo = this.opcaoSelecionada.tipo;

        this.selectionService.atualizar(this.selection);
      } else {
        this.opcaoSelecionada = this.opcoes.find(
          (o) =>
            o.modo === this.opcaoSelecionada.modo &&
            o.tipo === this.opcaoSelecionada.tipo
        );
      }
    }
  }

  public async btnBusca_click() {
    this.opcaoSelecionada.ativo = false;
    this.opcaoSelecionada = this.opcoes[0];

    this.selection.modo = this.opcaoSelecionada.modo;
    this.selection.tipo = this.opcaoSelecionada.tipo;
    this.selectionService.atualizar(this.selection);

    if (!this.painelBuscaAberto) {
      this.buscaService.abrirPainelBusca(null);
    } else {
      await this.buscaService.fecharPainelBusca(true);
    }
  }

  public toggle(opcao: OpcaoToolbar): void {
    this.opcaoSelecionada.ativo = false;
    if (opcao === this.opcaoSelecionada) {
      this.opcaoSelecionada = this.opcoes[0];
    } else {
      this.opcaoSelecionada = opcao;
    }
    this.opcaoSelecionada.ativo = true;

    this.selection.modo = this.opcaoSelecionada.modo;
    this.selection.tipo = this.opcaoSelecionada.tipo;
    this.selectionService.atualizar(this.selection);
  }

  public toggleSelecao() {
    this.opcaoSelecionada = null;

    this.selection.modo = ModoSelecao.Padrao;
    this.selection.tipo = null;
    this.selectionService.atualizar(this.selection);
  }
}
