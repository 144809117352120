import { Injectable } from "@angular/core";
import { LeiLookup } from "../../../models/lei/lei.lookup";
import { Observable, BehaviorSubject, Subscription } from "rxjs";
import {
  ItemLookupOrdenacaoNovaGuia,
  TipoOrdenacaoNovaGuia,
} from "../../../models/lei/item.lookup.ordenacao.nova.guia";
import { UsuarioEstatisticasService } from "../../../services/data-services/usuario.estatisticas.service";
import { EstatisticasLeitura } from "../../../models/usuario/EstatisticasLeitura";
import { StringHelper } from "../../../helpers/string.helper";
import { UsuarioGuiasService } from "../../../services/data-services/usuario.guias.service";
import { Guia } from "../../../models/Guia";
import { LeiRepositorio } from "../../../repositorios/lei.repositorio";
import { strict } from "assert";
import { debounceTime } from "rxjs/operators";

export const ModosOrdenacao: ItemLookupOrdenacaoNovaGuia[] = [
  {
    label: "Ordem alfabética",
    ordenacao: TipoOrdenacaoNovaGuia.OrdemAlfabetica,
  },
  { label: "Mais lidos", ordenacao: TipoOrdenacaoNovaGuia.MaisLidos },
  { label: "Menos lidos", ordenacao: TipoOrdenacaoNovaGuia.MenosLidos },
  {
    label: "Data de alteração",
    ordenacao: TipoOrdenacaoNovaGuia.DataAlteracao,
  },
];

@Injectable()
export class NovaGuiaService {
  private leisBase: LeiLookup[] = [];

  Leis: Observable<LeiLookup[]>;
  private leis = new BehaviorSubject<LeiLookup[]>(null);

  FiltrarFavoritos: Observable<boolean>;
  private filtrarFavoritos = new BehaviorSubject<boolean>(false);

  TextoBusca: Observable<string>;
  private textoBusca = new BehaviorSubject<string>(null);

  ModoOrdenacao: Observable<ItemLookupOrdenacaoNovaGuia>;
  private modoOrdenacao = new BehaviorSubject<ItemLookupOrdenacaoNovaGuia>(
    ModosOrdenacao[3]
  );

  EventosLoading: Observable<Array<string>>;
  private eventosLoading = new BehaviorSubject<Array<string>>(null);

  private subscriptions: Subscription[] = [];

  constructor(
    private leiRepositorio: LeiRepositorio,
    private usuarioEstatisticasService: UsuarioEstatisticasService,
    private usuarioGuiasService: UsuarioGuiasService
  ) {
    this.Leis = this.leis.asObservable();
    this.FiltrarFavoritos = this.filtrarFavoritos.asObservable();
    this.TextoBusca = this.textoBusca.asObservable();
    this.ModoOrdenacao = this.modoOrdenacao.asObservable();
    this.EventosLoading = this.eventosLoading.asObservable();

    setTimeout(() => {
      this.subscribeToObservables();
      //this.carregarLeis()
    });
  }

  subscribeToObservables() {
    this.subscriptions.forEach((sub) => sub.unsubscribe);
    this.subscriptions = [];

    //Sempre que guia é alterada, é alterada duas vezes
    this.subscriptions.push(
      this.usuarioGuiasService
        .getGuiaAtiva()
        .subscribe((guia) => this.guiaAtiva_changed(guia))
    );
    this.subscriptions.push(
      this.usuarioEstatisticasService.Favoritos.subscribe((fav) =>
        this.favoritos_changed(fav)
      )
    );
  }

  private alterarCarregando(carregando, nomeEvento) {
    if (carregando) {
      let eventos = this.eventosLoading.getValue();
      eventos = eventos ? eventos : new Array<string>();
      eventos.push(nomeEvento);
      this.eventosLoading.next(eventos);
    } else {
      let eventos = this.eventosLoading.getValue();
      let i = eventos.findIndex((x) => x == nomeEvento);
      eventos.splice(i, 1);

      setTimeout(() => {
        this.eventosLoading.next(eventos);
      }, 300);
    }
  }

  public async carregarLeis(reload = true) {
    const estatisticas = await this.usuarioEstatisticasService.listar();
    if (!this.leisBase || this.leisBase.length == 0) {
      this.leisBase = await this.leiRepositorio.carregarLookup();
    }

    for (const lei of this.leisBase) {
      lei.estatisticas = estatisticas.find((e) => e.id === lei.id);
      if (!lei.estatisticas) {
        const estatisticas = new EstatisticasLeitura();
        estatisticas.id = lei.id;

        await this.usuarioEstatisticasService.salvar(estatisticas);
        lei.estatisticas = estatisticas;
      }

      lei.progresso = EstatisticasLeitura.CalcularProgresso(
        lei.quantidadeItens,
        lei.estatisticas.linhasLidas.length
      );
      lei.favorita = lei.estatisticas.favorito;
    }

    this.aplicarFiltro();
  }

  public aplicarFiltro() {
    this.leis.next([]);

    const filtrarFavoritos = this.filtrarFavoritos.getValue();
    const modoOrdenacao = this.modoOrdenacao.getValue();
    const textoBusca = this.textoBusca.getValue();

    let lista = this.leisBase;
    if (!lista || lista.length == 0) return;

    if (filtrarFavoritos) lista = lista.filter((l) => l.favorita);

    if (textoBusca) {
      const query =
        StringHelper.RemoverAcentosCaracteresEspeciais(
          textoBusca
        ).toLowerCase();
      const words = query.split(" ");

      lista = lista.filter((l) => {
        let matches = 0;

        words.forEach((word) => {
          const tituloLei = StringHelper.RemoverAcentosCaracteresEspeciais(
            l.titulo
          ).toLowerCase();
          if (tituloLei.indexOf(word) !== -1) matches++;
        });

        return matches == words.length;
      });
    }

    switch (modoOrdenacao.ordenacao) {
      case TipoOrdenacaoNovaGuia.OrdemAlfabetica:
        lista = lista.sort((a1, a2) =>
          StringHelper.AlphabeticnaturalSort(a1.titulo, a2.titulo)
        );
        break;
      case TipoOrdenacaoNovaGuia.MaisLidos:
        lista = lista.sort((a1, a2) => a2.progresso - a1.progresso);
        break;
      case TipoOrdenacaoNovaGuia.MenosLidos:
        lista = lista.sort((a1, a2) => a1.progresso - a2.progresso);
        break;
      case TipoOrdenacaoNovaGuia.DataAlteracao:
        lista = lista.sort(
          (a1, a2) =>
            new Date(a2.dataHoraUltimaModificacaoOficial).getTime() -
            new Date(a1.dataHoraUltimaModificacaoOficial).getTime()
        );
        break;
    }

    setTimeout(() => {
      this.leis.next(lista);
    });
  }

  async alterarModoOrdenacao(novoModo: ItemLookupOrdenacaoNovaGuia) {
    let nomeEvento = "modoOrdenacao" + Date.now().toString();
    this.alterarCarregando(true, nomeEvento);

    this.modoOrdenacao.next(novoModo);
    await this.aplicarFiltro();

    this.alterarCarregando(false, nomeEvento);
  }

  async favoritos_toggle() {
    let nomeEvento = "favoritos_togle" + Date.now().toString();
    this.alterarCarregando(true, nomeEvento);

    const favoritos = this.filtrarFavoritos.getValue();
    this.filtrarFavoritos.next(!favoritos);

    await this.aplicarFiltro();

    this.alterarCarregando(false, nomeEvento);
  }

  async alterarTextoBusca(txtBusca: string) {
    let nomeEvento = "textoBusca" + Date.now().toString();
    this.alterarCarregando(true, nomeEvento);

    this.textoBusca.next(txtBusca);
    this.salvarBuscaGuia(txtBusca);

    await this.aplicarFiltro();

    this.alterarCarregando(false, nomeEvento);
  }

  favoritos_changed(fav: string[]): void {
    let nomeEvento = "favoritosChange" + Date.now().toString();
    this.alterarCarregando(true, nomeEvento);

    this.leisBase.forEach((lei) => {
      lei.favorita = fav.indexOf(lei.id) !== -1;
    });

    this.aplicarFiltro();

    this.alterarCarregando(false, nomeEvento);
  }

  guiaAtiva_changed(guia: Guia): void {
    let nomeEvento = "guiaChange" + Date.now().toString();
    this.alterarCarregando(true, nomeEvento);

    if (guia && guia.titulo == "Nova guia") {
      this.carregarLeis();
    }

    const busca = this.carregarBuscaGuia();
    this.alterarTextoBusca(busca ? busca : "");

    this.alterarCarregando(false, nomeEvento);
  }

  salvarBuscaGuia(txtBusca: string) {
    const guia = this.usuarioGuiasService.valueGuiaAtiva;
    localStorage.setItem(`busca-${guia.id}`, txtBusca);
  }

  carregarBuscaGuia() {
    const guia = this.usuarioGuiasService.valueGuiaAtiva;
    const retorno = localStorage.getItem(`busca-${guia.id}`);
    return retorno ? retorno : "";
  }
}
