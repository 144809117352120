import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfig } from "../app.config";
import { ErrorHandlerService } from "./errorHandler.service";
import { HistoricoAtualizacoes } from "../models/HistoricoAtualizacoes";
import { BehaviorSubject, combineLatest, concat, Observable } from "rxjs";
import { concatAll, map } from "rxjs/operators";
import { DataControleRepositorio } from "../repositorios/dataControle.repositorio";
import { DataControle, EnumTipoDataControle } from "../models/dataControle";

@Injectable({
  providedIn: "root",
})
export class HistoricoAtualizacoesService {
  private historicoAtualizacoesLeis: HistoricoAtualizacoes[] = [];
  private historicoAtualizacoesLeis$ = new BehaviorSubject<
    HistoricoAtualizacoes[]
  >(this.historicoAtualizacoesLeis);
  private historicoAtualizacoesComentarios: HistoricoAtualizacoes[] = [];
  private historicoAtualizacoesComentarios$ = new BehaviorSubject<
    HistoricoAtualizacoes[]
  >(this.historicoAtualizacoesComentarios);
  private historicoAtualizacoesReferencias: HistoricoAtualizacoes[] = [];
  private historicoAtualizacoesReferencias$ = new BehaviorSubject<
    HistoricoAtualizacoes[]
  >(this.historicoAtualizacoesReferencias);

  public lastDocumentsListCheck: DataControle = new DataControle();

  private historicoAtualizacoes$ = combineLatest([
    this.historicoAtualizacoesLeis$,
    this.historicoAtualizacoesComentarios$,
    this.historicoAtualizacoesReferencias$,
  ])
    .pipe(concatAll())
    .pipe(
      map((historico) =>
        historico.filter(
          async (his) => his.dataAtualizacao >= (await this.getdata()).data
        )
      )
    );

  constructor(
    private httpClient: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    private dataControleRepositorio: DataControleRepositorio
  ) {
    this.updateHistoricoAtualizacoesLeis();

    this.updateHistoricoAtualizacoesComentarios();

    this.updateHistoricoAtualizacoesReferencias();
  }

  public async getHistoricoAtualizacoes(
    historico: number
  ): Promise<HistoricoAtualizacoes[]> {
    let resposta;
    let url = `${AppConfig.apiEndpoint}/HistoricoAtualizacoes/GetHistoricoAtualizacoes`;
    let requestParams = new HttpParams();
    requestParams = requestParams.append(
      "TipoItemHistoricoAtualizacao",
      historico.toString()
    );
    await this.httpClient
      .get(url, { params: requestParams })
      .toPromise()
      .then((result: HistoricoAtualizacoes[]) => {
        resposta = result;
      })
      .catch((err) => {
        this.errorHandlerService.handleError(err);
        resposta = new Array<HistoricoAtualizacoes>();
      });
    return resposta;
  }

  public async getdata(): Promise<DataControle> {
    return await this.dataControleRepositorio.buscar(
      EnumTipoDataControle.DataVisualizacaoNovidades
    );
  }

  public getHistoricoAtualizacoesAll(): Observable<HistoricoAtualizacoes[]> {
    return this.historicoAtualizacoes$;
  }

  public getHistoricoAtualizacoesLeis(): Observable<HistoricoAtualizacoes[]> {
    return this.historicoAtualizacoesLeis$.asObservable();
  }

  public getHistoricoAtualizacoesComentarios(): Observable<
    HistoricoAtualizacoes[]
  > {
    return this.historicoAtualizacoesComentarios$.asObservable();
  }

  public getHistoricoAtualizacoesReferencias(): Observable<
    HistoricoAtualizacoes[]
  > {
    return this.historicoAtualizacoesReferencias$.asObservable();
  }

  public async updateHistoricoAtualizacoesLeis() {
    this.historicoAtualizacoesLeis = await this.getHistoricoAtualizacoes(0);
    this.historicoAtualizacoesLeis$.next(this.historicoAtualizacoesLeis);
  }

  public async updateHistoricoAtualizacoesComentarios() {
    this.historicoAtualizacoesLeis = await this.getHistoricoAtualizacoes(2);
    this.historicoAtualizacoesComentarios$.next(
      this.historicoAtualizacoesComentarios
    );
  }

  public async updateHistoricoAtualizacoesReferencias() {
    this.historicoAtualizacoesLeis = await this.getHistoricoAtualizacoes(1);
    this.historicoAtualizacoesReferencias$.next(
      this.historicoAtualizacoesReferencias
    );
  }
}
