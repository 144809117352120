import { BuscaPanelParameters } from "./../../leitor-content-panelbusca/busca-panel.parameters";
import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  HostListener,
  OnDestroy,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  AfterViewChecked,
  ChangeDetectionStrategy,
} from "@angular/core";
import { Datasource } from "ngx-ui-scroll";
import { BehaviorSubject, Subscription, Observable } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { Conteudo } from "../../../models/pagina/conteudo";
import { EntitiesHelper } from "../../../helpers/entities.helper";
import {
  TextoPagina,
  TextoPaginaFunctions,
} from "../../../models/pagina/TextoPagina";
import { SelectionHelper } from "../../../helpers/selection.helper";
import {
  Selection,
  Selector,
  SelectionService,
  ModoSelecao,
  TipoSelecao,
} from "../../../services/selection.service";
import { Comentario } from "../../../models/Comentario";
import { Marcacao, FuncoesProva } from "../../../models/Marcacao";
import { Grifo } from "../../../models/Grifo";
import { ConteudoService } from "../../../services/conteudo.service";
import { MatchBuscaTexto } from "../../../models/MatchBuscaTexto";

import { ShortcutsService } from "../../../services/shortcuts.service";
import { UsuarioPreferenciasService } from "../../../services/data-services/usuario.preferencias.service";
import { UsuarioMarcacoesService } from "../../../services/data-services/usuario.marcacoes.service";
import { UsuarioGrifosService } from "../../../services/data-services/usuario.grifos.service";
import { UsuarioEstatisticasService } from "../../../services/data-services/usuario.estatisticas.service";
import { DomSanitizer } from "@angular/platform-browser";
import { ParametrosCaneta, ProvaDatasource } from "../../../models/UserData";
import { EstatisticasLeitura } from "../../../models/usuario/EstatisticasLeitura";
import { MdePopoverTrigger } from "@material-extended/mde";
import { TagConteudo } from "../../../interfaces/TagConteudo";
import { HoverService } from "../../../services/hover.service";
import { UsuarioComentariosService } from "../../../services/data-services/usuario.comentarios.service";
// azure card 358 Desativar botão caiu em prova import { UsuarioProvasService } from "../../../services/data-services/usuario.provas.service";
import { TagPickerComponent } from "../placeholder-paineis/painel-marcacoes-prova/tag-picker/tag-picker.component";
import { ConfiguracoesUsuario } from "../../../models/usuario/ConfiguracoesUsuario";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { LoggingService } from "../../../services/logging.service";
import { BuscaRapidaService } from "../../../controls/busca/form-busca-artigo-documento-atual/busca.rapida.service";
import { QuizQuestions, QuizService } from "src/app/services/quiz.service";
import { Referencia } from "src/app/models/Referencia";
import { UsuarioReferenciaService } from "src/app/services/data-services/usuario.referencia.service";
import { FeatureFlagService } from "src/app/services/feature-flag-service.service";
import { BuscaService } from "src/app/services/busca.service";
import { PaginaModel } from "src/app/models/PaginaModel";

@Component({
  selector: "app-pagina",
  templateUrl: "./pagina.component.html",
  styleUrls: ["./pagina.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginaComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked
{
  constructor(
    private quizService: QuizService,
    private sanitizer: DomSanitizer,
    private conteudoService: ConteudoService,
    private selectionService: SelectionService,
    private shortcutsService: ShortcutsService,
    private hoverService: HoverService,
    private usuarioPreferenciasService: UsuarioPreferenciasService,
    private usuarioMarcacoesService: UsuarioMarcacoesService,
    private usuarioGrifosService: UsuarioGrifosService,
    private usuarioComentariosService: UsuarioComentariosService,
    private usuarioEstatisticasService: UsuarioEstatisticasService,
    private usuarioReferenciaService: UsuarioReferenciaService,
    // azure card 358 Desativar botão caiu em prova private usuarioProvasService: UsuarioProvasService,
    private loggingService: LoggingService,
    private changeDetector: ChangeDetectorRef,
    private buscaRapidaService: BuscaRapidaService,
    public featureFlagService: FeatureFlagService,
    private buscaService: BuscaService
  ) {
    // inicializar quantidade de linhas com 15
    this.qntLinhasCarregando = Array(18)
      .fill(18)
      .map((x, i) => i);
    this.carregandoConteudo = conteudoService.Carregando;
    this.IsAdm = featureFlagService.IsAdm(
      this.usuarioPreferenciasService.Configuracoes.email
    );
    this.IsPrintAdm = featureFlagService.IsPrintAdm(
      this.usuarioPreferenciasService.Configuracoes.email
    );
  }

  private _bufferTextAreaComentario: HTMLTextAreaElement;
  private _bufferMnemonicoInput: HTMLSpanElement;

  public model = new PaginaModel();

  public quizQuestions: QuizQuestions;
  public shouldOpen: boolean = false;
  public hoverQuiz: boolean = false;
  public quizArtRange: Array<any> = [];
  public selection: Selection;
  public conteudo: Conteudo;
  public indexPrimeiroItem: number;
  public utlimoItemFocoId: string;
  public ultimoTitulo: string;
  public busca: BuscaPanelParameters;
  public carregandoConteudo: Observable<boolean>;
  public qntLinhasCarregando: Array<number>;
  public preferenciasUsuario: ConfiguracoesUsuario;
  public prefixoBuscaRapida: string;

  public exibirRevogados: boolean;
  public exibirComentariosSvm: boolean;
  public exibirMeusComentarios: boolean;
  public exibirApenasItensComentados: boolean;
  public exibirItensRevogados: boolean;

  // Parâmetros Pop Over
  public popOverAberta: boolean;
  public comentarioComentando: Comentario;

  public criarReferencia: Referencia;

  public textoComentarioComentando: string;
  public mnemonicoComentando: boolean;
  public provaMarcando: Marcacao;
  public carregandoOpcoesProva = true;
  public opcoesProva: ProvaDatasource;
  public provaNova = false;
  public funcoesProva = FuncoesProva;
  public marginLeftPopOver: number;
  public marginLeftContentPopOver: number;
  public IsAdm: boolean = false;
  public IsPrintAdm: boolean = false;

  public classlarguraPapel: string;

  public printing = false;

  private SetMarginLeftContentPopOver() {
    const popOverPai = document.querySelector(".texto");
    let m = popOverPai
      ? this.marginLeftPopOver - popOverPai.getBoundingClientRect().left - 60
      : this.marginLeftPopOver;
    m = m * -1;
    this.marginLeftContentPopOver = m;
  }
  private getMarginLeftDefaultText(): number {
    const popOverPai = document.querySelector(".texto");
    const ret = popOverPai.getBoundingClientRect().left;
    return ret;
  }
  // Final Parâmetros Pop Over

  private subscriptions: Subscription[] = [];

  public cursor: any;
  opcoesCoresPonteiros: ParametrosCaneta;
  private titulosDic: {};

  private quantidadeItensBuffer = 35;
  public linhasPaginaDataSource = new Datasource({
    get: (index, count) => {
      this.carregando = true;
      const data = new BehaviorSubject<TextoPagina[]>(null);

      if (!this.conteudo || this.conteudo.linhas.length === 0) {
        data.next(new Array<TextoPagina>());
        this.carregando = false;
        return data.asObservable();
      }

      const itens = new Array<TextoPagina>();
      // Inserir itens, ajustando apenas a primeira e ultima linha
      for (let i = index; i <= index + count - 1; i++) {
        if (i > 0 && i < this.conteudo.linhas.length - 1) {
          itens.push(this.conteudo.linhas[i]);
        } else if (i === 0 || i === this.conteudo.linhas.length - 1) {
          const linhaInicialJaInclusa =
            itens.findIndex(
              (linha) => linha.id === this.conteudo.linhas[i].id
            ) !== -1;
          if (!linhaInicialJaInclusa) {
            itens.push(this.conteudo.linhas[i]);
          }
        }
      }

      // Enviar dados
      data.next(itens);

      this.carregando = false;
      return data.asObservable();
    },
    settings: {
      startIndex: 0,
      bufferSize: this.quantidadeItensBuffer,
    },
  });

  @ViewChild("Pagina") areaSelecao: ElementRef;
  @ViewChild("revogados") exibirRevogadosToggle: MatSlideToggle;
  carregando: boolean;
  estatisticas: EstatisticasLeitura;

  // Pop Over Marcações
  @ViewChildren(MdePopoverTrigger)
  triggersPopOver: QueryList<MdePopoverTrigger>;
  @ViewChild("instituicaoElement") set instituicaoElement(
    inst: TagPickerComponent
  ) {
    if (!inst) {
      return;
    }
    inst.focus = true;
  }

  // Evento antes de recarregar pagina
  @HostListener("window:beforeunload", ["$event"])
  beforeunloadHandler(event) {
    if (this.utlimoItemFocoId) {
      this.gravarIdItemLeitura(this.utlimoItemFocoId);
    }
  }

  // Evento antes de recarregar pagina
  @HostListener("window:keypress", ["$event"])
  keyPressListener(event: KeyboardEvent) {
    if (this.shortcutsService.listenToHotkeys && event && event.keyCode) {
      switch (event.keyCode) {
        case 49:
          console.log("toggle marcação prova");
          break;
        case 50:
          console.log("toggle comentário");
          break;
        case 51:
          console.log("toggle caneta 1");
          break;
        case 52:
          console.log("toggle caneta 2");
          break;
        case 53:
          console.log("toggle caneta 3");
          break;
        case 54:
          console.log("toggle caneta 4");
          break;
        case 55:
          console.log("toggle caneta 5");
          break;
        case 56:
          console.log("toggle borracha");
          break;
        default:
          console.log(`Atalho não utilizado: ${event.keyCode}`);
          break;
      }
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.usuarioPreferenciasService.$Configuracoes.subscribe((p) =>
        this.preferencias_changed(p)
      )
    );
    this.subscriptions.push(
      this.conteudoService.Conteudo.subscribe(
        async (cont) => await this.conteudo_subscribe(cont)
      )
    );
    this.subscriptions.push(
      this.conteudoService.Conteudo.pipe(debounceTime(1000)).subscribe(
        async (cont) => await this.conteudo_subscribeLog(cont)
      )
    );
    this.subscriptions.push(
      this.conteudoService.IndexFoco.subscribe((i) => this.carregarConteudo(i))
    );

    this.subscriptions.push(
      this.buscaService.getBusca().subscribe((c) => this.busca_changed(c))
    );
    this.subscriptions.push(
      this.selectionService.$Selection.subscribe(
        async (sel) => await this.selection_subscribe(sel)
      )
    );

    this.subscriptions.push(
      this.linhasPaginaDataSource.adapter.firstVisible$.subscribe((item) => {
        if (item && item.data) {
          this.indexPrimeiroItem = item.$index;
          this.utlimoItemFocoId = (<TextoPagina>item.data).id;
          this.changeDetector.markForCheck();
        }
      })
    );

    this.subscriptions.push(
      this.buscaRapidaService.MatchBuscaRapida.subscribe((m) => {
        if (!m) return;

        let indexFocar = this.conteudo.linhas.findIndex(
          (linha) => linha.id === m.idOrigem
        );
        indexFocar = indexFocar - 2 > 0 ? indexFocar - 2 : 0;

        this.carregarConteudo(indexFocar);
      })
    );

    this.subscriptions.push(
      this.linhasPaginaDataSource.adapter.isLoading$
        .pipe(debounceTime(500))
        .subscribe((l) => this.virtualScrollLoading_subscribe(l))
    );
    this.subscriptions.push(
      this.linhasPaginaDataSource.adapter.init$.subscribe((i) =>
        this.CarregarConteudoIdentificandoFocoInicial(this.conteudo)
      )
    );
    this.subscriptions.push(
      this.usuarioPreferenciasService.$Configuracoes.subscribe((p) => {
        this.classlarguraPapel =
          "documento-conteudo" + p.preferenciasUsuario.larguraPapel;
      })
    );
    this.atualizarCabecalho();
  }

  public ngAfterViewInit(): void {
    const el = <HTMLElement>this.areaSelecao.nativeElement;
    SelectionHelper.Init(
      el,
      null,
      (selector: Selector) => this.processarSelecao(selector),
      () => {}
    );

    if (this.conteudo) {
      this.CarregarConteudoIdentificandoFocoInicial(this.conteudo);
    }
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = [];
  }

  preferencias_changed(p: ConfiguracoesUsuario): void {
    if (p) {
      this.opcoesCoresPonteiros = p.parametrosCaneta;
      this.preferenciasUsuario = p;
      this.exibirMeusComentarios =
        p.preferenciasUsuario.exibirMeusComentarios ?? true;
      this.exibirComentariosSvm =
        p.preferenciasUsuario.exibirComentariosSvm ?? true;
      this.exibirApenasItensComentados =
        p.preferenciasUsuario.exibirApenasItensComentados ?? false;
      this.exibirItensRevogados =
        p.preferenciasUsuario.exibirItensRevogados ?? true;
    }
  }

  private virtualScrollLoading_subscribe(l: boolean) {
    this.atualizarCabecalho();
  }

  fecharPopOvers(commit = true) {
    this.triggersPopOver
      .filter((x) => x.popoverOpen)
      .forEach((p) => p.closePopover());
    this.popOverAberta = false;

    if (commit) {
      if (this.selection && (this.comentarioComentando || this.provaMarcando)) {
        this.salvarMarcacao();
      }

      setTimeout(() => {
        this.comentarioComentando = null;
        this.provaMarcando = null;
        this.textoComentarioComentando = "";
        this.provaNova = false;
      }, 150);

      this.selection.selector = null;
      this.selectionService.atualizar(this.selection);
      this.hoverService.destacar(null);
    }
  }

  public salvarMarcacao() {
    // Comentarios
    if (this.comentarioComentando) {
      if (this.comentarioComentando.texto.length > 0) {
        const reg = /\n/g;
        if (this.comentarioComentando.texto.match(reg)) {
          const linhas = this.comentarioComentando.texto.split(reg);
          let maiorLinha = "";
          linhas.forEach((l) => {
            if (l.length > maiorLinha.length) {
              maiorLinha = l;
            }
          });
          this.comentarioComentando.larguraExibicao =
            this.getWidthOfText(maiorLinha);
        } else if (this.comentarioComentando.texto.length <= 35) {
          this.comentarioComentando.larguraExibicao = this.getWidthOfText(
            this.comentarioComentando.texto
          );
        }
        this.usuarioComentariosService.atualizar(this.comentarioComentando);
      }
    }

    // Provas
    // azure card 358 Desativar botão caiu em prova
    // if (this.provaMarcando) {
    //   if (this.provaNova) {
    //     if (this.provaMarcando.dados.ano) {
    //       this.usuarioProvasService.incluirAno(this.provaMarcando.dados.ano);
    //     }
    //     if (this.provaMarcando.dados.banca) {
    //       this.usuarioProvasService.incluirBanca(
    //         this.provaMarcando.dados.banca
    //       );
    //     }
    //     if (this.provaMarcando.dados.cargo) {
    //       this.usuarioProvasService.incluirCargo(
    //         this.provaMarcando.dados.cargo
    //       );
    //     }
    //     if (this.provaMarcando.dados.instituicao) {
    //       this.usuarioProvasService.incluirInstituicao(
    //         this.provaMarcando.dados.instituicao
    //       );
    //     }
    //     if (this.provaMarcando.dados.tipo) {
    //       this.usuarioProvasService.incluirTipo(this.provaMarcando.dados.tipo);
    //     }
    //   }

    //   this.usuarioMarcacoesService.atualizar(this.provaMarcando);
    // }
  }

  public async atualizarCabecalho() {
    if (
      this.titulosDic &&
      this.linhasPaginaDataSource &&
      this.linhasPaginaDataSource.adapter &&
      this.linhasPaginaDataSource.adapter.firstVisible
    ) {
      this.ultimoTitulo =
        this.titulosDic[
          this.linhasPaginaDataSource.adapter.firstVisible.$index
        ];
    }
  }

  editandoComentario(idLinha: string) {
    if (this.comentarioComentando) {
      const posicionamento = !this.comentarioComentando.mnemonico
        ? this.preferenciasUsuario.parametrosCaneta.posicionamentoComentario
        : this.preferenciasUsuario.parametrosCaneta.posicionamentoMnemonico;
      const index =
        posicionamento == "Acima"
          ? 0
          : this.comentarioComentando.range.idItens.length - 1;
      const idVersao = this.comentarioComentando.range.idItens[index];

      return idVersao.idItem === idLinha;
    }

    return false;
  }

  cancelarComentario() {
    this.fecharPopOvers();
  }

  salvarComentario(comentario: Comentario) {
    this.comentarioComentando = comentario;
    this.fecharPopOvers();
  }

  processarSelecaoMarcacaoComentario(mnem: boolean) {
    const item = this.conteudo.linhas.find(
      (i) => i.id === this.selection.selector.selected[0].idItem
    );
    const comentario = new Comentario();
    comentario.range.idItens = this.selection.selector.selected;
    comentario.range.inicio = this.selection.selector.start;
    comentario.range.termino = this.selection.selector.end;
    comentario.idLei = item.idLei;
    comentario.mnemonico = mnem;

    comentario.texto = "";
    this.comentarioComentando = comentario;

    // Realçar comentario
    const col = new Array<TagConteudo>();
    const tag = new TagConteudo(null, comentario);
    col.push(tag);
    this.hoverService.destacar(col);

    this.fecharPopOvers(false);
  }

  processarSelecaoMarcacaoProva() {
    const item = this.conteudo.linhas.find(
      (i) => i.id === this.selection.selector.selected[0].idItem
    );
    const prova = new Marcacao();
    prova.range.idItens = this.selection.selector.selected;
    prova.range.inicio = this.selection.selector.start;
    prova.range.termino = this.selection.selector.end;
    prova.idLei = item.idLei;

    this.provaMarcando = prova;
    // azure card 358 Desativar botão caiu em prova this.carregarOpcoesProva();

    // Realçar prova
    const col = new Array<TagConteudo>();
    const tag = new TagConteudo(prova, null);
    col.push(tag);
    this.hoverService.destacar(col);
  }
  // azure card 358 Desativar botão caiu em prova
  // public carregarOpcoesProva() {
  //   this.carregandoOpcoesProva = true;
  //   this.usuarioProvasService.carregarDatasource().then((ds) => {
  //     this.opcoesProva = ds;
  //   });

  //   let todasProvas: Marcacao[];
  //   this.usuarioMarcacoesService.listar().then((provas) => {
  //     todasProvas = provas;

  //     const ultimaProva = provas.sort((a, b) => {
  //       if (a.dataHoraModificacao > b.dataHoraModificacao) {
  //         return -1;
  //       } else if (a.dataHoraModificacao < b.dataHoraModificacao) {
  //         return 1;
  //       }
  //     })[0];

  //     if (ultimaProva) {
  //       this.carregandoOpcoesProva = false;
  //       this.provaMarcando.dados = ultimaProva.dados;
  //       this.fecharPopOvers();
  //     } else {
  //       this.carregandoOpcoesProva = false;
  //       this.provaNova = todasProvas.length > 0 ? false : true;
  //     }
  //   });
  // }

  private processarSelecao(selector: Selector): void {
    if (!selector) {
      return;
    }

    // Finalizar popovers anteriores
    this.fecharPopOvers();
    this.comentarioComentando = null;
    this.provaMarcando = null;

    const processarSelecaoMarcaTexto = () => {
      const grifos = new Array<Grifo>();
      this.selection.selector.selected.forEach((id) => {
        const item = this.conteudo.linhas.find((i) => i.id === id.idItem);
        const grifo = new Grifo();

        grifo.idItem = item.id;
        grifo.idLei = item.idLei;

        switch (this.selection.tipo) {
          case TipoSelecao.Caneta1:
            grifo.tipo = TipoSelecao.Caneta1;
            break;
          case TipoSelecao.Caneta2:
            grifo.tipo = TipoSelecao.Caneta2;
            break;
          case TipoSelecao.Caneta3:
            grifo.tipo = TipoSelecao.Caneta3;
            break;
          case TipoSelecao.Caneta4:
            grifo.tipo = TipoSelecao.Caneta4;
            break;
          case TipoSelecao.Caneta5:
            grifo.tipo = TipoSelecao.Caneta5;
            break;
        }

        grifo.inicio =
          this.selection.selector.selected.indexOf(id) > 0
            ? -1
            : this.selection.selector.start;
        grifo.termino =
          this.selection.selector.selected.indexOf(id) <
          this.selection.selector.selected.length - 1
            ? -1
            : this.selection.selector.end;
        grifo.idImportacao = id.idImportacao;

        grifos.push(grifo);
      });

      this.usuarioGrifosService.atualizarVarios(grifos);
    };

    const processarSelecaoBorracha = () => {
      return new Promise((onsuccess) => {
        const grifos = new Array<Grifo>();
        const ids = this.selection.selector.selected;

        const tasks = new Array<Promise<any>>();

        ids.forEach((id) => {
          tasks.push(
            this.usuarioGrifosService
              .buscarLinha(id.idItem, id.idImportacao)
              .then((check) => {
                const inicioSelecao =
                  this.selection.selector.selected.indexOf(id) > 0
                    ? -1
                    : this.selection.selector.start;
                const terminoSelecao =
                  this.selection.selector.selected.indexOf(id) <
                  this.selection.selector.selected.length - 1
                    ? -1
                    : this.selection.selector.end;

                check.forEach((grifo) => {
                  if (
                    (inicioSelecao < grifo.inicio &&
                      terminoSelecao > grifo.inicio) ||
                    (inicioSelecao < grifo.termino &&
                      terminoSelecao > grifo.termino) ||
                    (grifo.inicio === -1 && grifo.termino === -1) ||
                    (inicioSelecao === -1 && terminoSelecao === -1)
                  ) {
                    grifos.push(grifo);
                  }
                });
              })
          );
        });

        Promise.all(tasks).then(() => {
          if (grifos.length > 0) {
            this.usuarioGrifosService.removerVarios(grifos);
          }
        });
      });
    };

    this.selection.selector = selector;
    switch (this.selection.modo) {
      case ModoSelecao.Padrao:
        // document.execCommand('Copy');
        const trigAtual = this.triggersPopOver.find(
          (x) =>
            x._elementRef.nativeElement.id.indexOf(
              selector.selectedSourceId
            ) !== -1
        );
        if (trigAtual) {
          const def = this.getMarginLeftDefaultText();
          this.marginLeftPopOver = selector.clientX - def;
          this.SetMarginLeftContentPopOver();
          trigAtual.togglePopover();
          this.popOverAberta = true;
        }
        break;
      case ModoSelecao.Marcacao:
        this.selection.selector = null;
        this.selectionService.atualizar(this.selection);
        break;
      case ModoSelecao.MarcaTexto:
        processarSelecaoMarcaTexto();
        this.selection.selector = null;
        this.selectionService.atualizar(this.selection);
        window.getSelection().empty();
        break;
    }
  }

  private selection_subscribe(selection: Selection): void {
    if (selection) {
      this.selection = selection;

      switch (selection.tipo) {
        case TipoSelecao.Comentario:
          this.alterar_cursor("d", this.opcoesCoresPonteiros.corTagComentario);
          break;
        case TipoSelecao.Prova:
          this.alterar_cursor("a", this.opcoesCoresPonteiros.corTagProva);
          break;
        case TipoSelecao.Caneta1:
          if (
            !this.opcoesCoresPonteiros.modoRealceCaneta1 ||
            this.opcoesCoresPonteiros.modoRealceCaneta1 === "Grifar"
          ) {
            this.alterar_cursor("t", this.opcoesCoresPonteiros.corCaneta1);
          } else {
            this.alterar_cursor("E", this.opcoesCoresPonteiros.corCaneta1);
          }
          break;
        case TipoSelecao.Caneta2:
          if (
            !this.opcoesCoresPonteiros.modoRealceCaneta2 ||
            this.opcoesCoresPonteiros.modoRealceCaneta2 === "Grifar"
          ) {
            this.alterar_cursor("t", this.opcoesCoresPonteiros.corCaneta2);
          } else {
            this.alterar_cursor("E", this.opcoesCoresPonteiros.corCaneta2);
          }
          break;
        case TipoSelecao.Caneta3:
          if (
            !this.opcoesCoresPonteiros.modoRealceCaneta3 ||
            this.opcoesCoresPonteiros.modoRealceCaneta3 === "Grifar"
          ) {
            this.alterar_cursor("t", this.opcoesCoresPonteiros.corCaneta3);
          } else {
            this.alterar_cursor("E", this.opcoesCoresPonteiros.corCaneta3);
          }
          break;
        case TipoSelecao.Caneta4:
          if (
            !this.opcoesCoresPonteiros.modoRealceCaneta4 ||
            this.opcoesCoresPonteiros.modoRealceCaneta4 === "Grifar"
          ) {
            this.alterar_cursor("t", this.opcoesCoresPonteiros.corCaneta4);
          } else {
            this.alterar_cursor("E", this.opcoesCoresPonteiros.corCaneta4);
          }
          break;
        case TipoSelecao.Caneta5:
          if (
            !this.opcoesCoresPonteiros.modoRealceCaneta5 ||
            this.opcoesCoresPonteiros.modoRealceCaneta5 === "Grifar"
          ) {
            this.alterar_cursor("t", this.opcoesCoresPonteiros.corCaneta5);
          } else {
            this.alterar_cursor("E", this.opcoesCoresPonteiros.corCaneta5);
          }
          break;
        default:
          if (selection.modo === ModoSelecao.Borracha) {
            this.alterar_cursor("f", "#919191");
          } else {
            this.cursor = null;
          }
          break;
      }
    }
  }

  public alterar_cursor(caracter: string, cor: string) {
    const canvas = document.createElement("canvas");
    canvas.width = 60;
    canvas.height = 50;

    const ctx = canvas.getContext("2d");
    ctx.fillStyle = cor;
    ctx.arc(22, 13, 12, 0, Math.PI * 2, true);
    ctx.fill();

    ctx.fillStyle = "#000000";
    ctx.font = "15px svm-ui";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    //Verificar se é borracha ou não
    caracter === "f" ? ctx.fillText("D", 4, 8) : ctx.fillText("A", 4, 8);

    ctx.fillStyle = "#FFFFFF";
    // ctx.fillStyle = cor;
    ctx.font = "15px svm-ui";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(caracter, 22, 13);

    const dataURL = canvas.toDataURL("image/png");
    this.cursor = this.sanitizer.bypassSecurityTrustStyle(
      "url(" + dataURL + "), auto"
    );
  }

  private mudouLinhas(linhas: TextoPagina[]) {
    const atual = this.conteudo.linhas.map((l) => l.id);
    const nova = linhas.map((l) => l.id);

    return !EntitiesHelper.equals(atual, nova);
  }

  private async conteudo_subscribe(cont: Conteudo) {
    if (!cont) return;

    // console.log('1 - Verificar se já existe conteúdo na página')
    if (this.conteudo && this.utlimoItemFocoId) {
      // console.log('1.1 - gravar última posição de leitura')
      this.gravarIdItemLeitura(this.utlimoItemFocoId);
      this.utlimoItemFocoId = null;
    }

    // console.log('2 - Verificar se o conteúdo foi alterado')
    if (!this.conteudo || this.mudouLinhas(cont.linhas)) {
      // console.log('2.1 - carregar conteúdo novo e rolar para o ponto definido')
      this.conteudo = Conteudo.clone(cont);
      this.CarregarConteudoIdentificandoFocoInicial(this.conteudo);
    }

    // console.log('3 - Criar dicionário de títulos')
    let _ultimoTitulo;
    this.titulosDic = {};
    for (let index = 0; index < cont.linhas.length; index++) {
      if (
        cont.linhas[index].tipoTexto === "texto-tituloprincipal" ||
        cont.linhas[index].tipoTexto === "texto-parte" ||
        cont.linhas[index].tipoTexto === "texto-livro" ||
        cont.linhas[index].tipoTexto === "texto-titulo" ||
        cont.linhas[index].tipoTexto === "texto-capitulo" ||
        cont.linhas[index].tipoTexto === "texto-secao" ||
        cont.linhas[index].tipoTexto === "texto-subsecao"
      ) {
        _ultimoTitulo = TextoPaginaFunctions.getVersao(
          cont.linhas[index]
        ).texto;
      }

      this.titulosDic[index] = _ultimoTitulo;
    }

    // console.log('5 - Verificar se mudou estatísticas')
    if (
      cont.estatisticas &&
      !EntitiesHelper.equals(cont.estatisticas, this.estatisticas)
    ) {
      // console.log('5.1 - Atualizar estatísticas')

      this.estatisticas = cont.estatisticas;
      this.atualizarEstatisticas();
    }
  }
  private async busca_changed(cont: BuscaPanelParameters) {
    // console.log('4 - Verificar se é uma busca e foi alterada')
    if (
      cont &&
      !EntitiesHelper.equals(cont, this.busca) &&
      cont.matchResultadoBuscaFoco
    ) {
      // console.log('4.1 - Atualizar busca e navegar para o resultado focado')
      this.busca = cont;
      this.navegarScrollMatchAtualBusca(cont.matchResultadoBuscaFoco);
    }
  }

  private async conteudo_subscribeLog(cont: Conteudo) {
    if (!cont) {
      return;
    }

    this.loggingService.LogEvent(
      "Leitor - Carregar lei",
      "lei",
      cont.tituloGuia
    );
  }

  private atualizarEstatisticas() {
    if (this.estatisticas && this.conteudo) {
      this.conteudo.linhas.forEach((linha) => {
        linha.lida = this.estatisticas.linhasLidas.find((l) => l === linha.id)
          ? true
          : false;
      });
    }
  }

  private navegarScrollMatchAtualBusca(m: MatchBuscaTexto) {
    let indexFocar = this.conteudo.linhas.findIndex(
      (linha) => linha.id === m.textoItemId
    );
    indexFocar = indexFocar - 2 > 0 ? indexFocar - 2 : 0;

    this.carregarConteudo(indexFocar);
  }

  public CarregarConteudoIdentificandoFocoInicial(conteudo: Conteudo) {
    if (!conteudo && !this.linhasPaginaDataSource.adapter.init) {
      return;
    }

    if (!conteudo || !conteudo.linhas || conteudo.linhas.length <= 0) {
      this.carregarConteudo(0);
      return;
    }

    let itemFoco = null;
    if (conteudo.estatisticas && conteudo.estatisticas.idUltimoItemLido) {
      itemFoco = conteudo.linhas.findIndex(
        (l) => l.id === conteudo.estatisticas.idUltimoItemLido
      );
    }

    const indexFoco = itemFoco ? itemFoco : 0;
    this.carregarConteudo(indexFoco);
  }

  public carregarConteudo(index: number) {
    index = index === -1 ? 0 : index;

    this.atualizarEstatisticas();

    if (this.linhasPaginaDataSource.adapter.init) {
      this.linhasPaginaDataSource.adapter.reload(index);
      this.changeDetector.detectChanges();
    }
  }

  public gravarIdItemLeitura(idItem: string): void {
    const idLei = Conteudo.getIdLei(this.conteudo);
    if (!idLei) return;

    this.usuarioEstatisticasService.alterarPosicaoLeitura(idLei, idItem);
  }

  public toggleHoverPopOverButton(button: HTMLElement) {
    const corFundo = button.style.color;
    const corTexto = button.style.backgroundColor;

    button.style.backgroundColor =
      corFundo === "rgb(14, 72, 117)"
        ? this.opcoesCoresPonteiros.corTagComentario
        : corFundo;

    button.style.color =
      corTexto === "rgb(233, 235, 245)" ? "#0E4875" : corTexto;
  }

  public resizeTxtAreaComentarios(targ: HTMLTextAreaElement) {
    // targ.style.cssText = 'height:auto; padding:0';
    const div = document.getElementById("div-" + targ.id);

    // if (targ.scrollHeight > targ.clientHeight) {
    //   const tamanhoFinal = targ.scrollHeight + 28.4;
    //   div.style.height = tamanhoFinal + 'px';
    //   targ.style.height = '100%';
    // }

    if (!this._bufferTextAreaComentario) {
      this._bufferTextAreaComentario = document.createElement("textarea");
      this._bufferTextAreaComentario.style.border = "none";
      this._bufferTextAreaComentario.style.height = "0";
      this._bufferTextAreaComentario.style.overflow = "hidden";
      this._bufferTextAreaComentario.style.padding = "0";
      this._bufferTextAreaComentario.style.position = "absolute";
      this._bufferTextAreaComentario.style.left = "0";
      this._bufferTextAreaComentario.style.top = "0";
      this._bufferTextAreaComentario.style.zIndex = "-1";
      document.body.appendChild(this._bufferTextAreaComentario);
    }

    var cs = window.getComputedStyle(targ);
    var pl = parseInt(cs.paddingLeft);
    var pr = parseInt(cs.paddingRight);
    var lh = parseInt(cs.lineHeight);

    // [cs.lineHeight] may return 'normal', which means line height = font size.
    if (isNaN(lh)) lh = parseInt(cs.fontSize);

    // Copy content width.
    this._bufferTextAreaComentario.style.width =
      targ.clientWidth - pl - pr + "px";

    // Copy text properties.
    this._bufferTextAreaComentario.style.font = cs.font;
    this._bufferTextAreaComentario.style.letterSpacing = cs.letterSpacing;
    this._bufferTextAreaComentario.style.whiteSpace = cs.whiteSpace;
    this._bufferTextAreaComentario.style.wordBreak = cs.wordBreak;
    this._bufferTextAreaComentario.style.wordSpacing = cs.wordSpacing;
    this._bufferTextAreaComentario.style.wordWrap = cs.wordWrap;

    // Copy value.
    this._bufferTextAreaComentario.value = targ.value;

    var result = Math.ceil(this._bufferTextAreaComentario.scrollHeight / lh);
    if (result == 0) {
      result = 1;
    }

    //limitar numero maximo de linhas
    if (result > 15) {
      targ.value = targ.value.substring(0, targ.value.length - 1);
      result = 15;
    }

    // Ajustar height
    if (result === 1) {
      div.style.height = 44 + "px";
    } else {
      div.style.height = 20 + 15 * result + "px";
    }
    targ.style.height = "100%";
  }

  public getWidthOfText(texto: string) {
    {
      // position: absolute !important; font-size: 10pt;height: 0 !important; overflow: hidden !important;
      this._bufferMnemonicoInput = document.createElement("span");
      this._bufferMnemonicoInput.style.border = "none";
      this._bufferMnemonicoInput.style.height = "0";
      this._bufferMnemonicoInput.style.overflow = "hidden";
      this._bufferMnemonicoInput.style.padding = "0";
      this._bufferMnemonicoInput.style.position = "absolute";
      this._bufferMnemonicoInput.style.left = "0";
      this._bufferMnemonicoInput.style.top = "0";
      this._bufferMnemonicoInput.style.zIndex = "-1";
      this._bufferMnemonicoInput.style.fontSize = "10pt";
      document.body.appendChild(this._bufferMnemonicoInput);
    }

    this._bufferMnemonicoInput.textContent = texto;

    const width = this._bufferMnemonicoInput.offsetWidth + 40;
    return width;
    // this.el.nativeElement.style.width = width.toString() + 'px';
  }

  public print(): void {
    this.printing = true;
    setTimeout(() => {
      var originalContents = document.body.innerHTML;
      var printReport = document.getElementById("print-section").innerHTML;
      document.body.innerHTML = printReport;
      window.print();
      document.body.innerHTML = originalContents;
      this.printing = false;
    }, 1000);
  }

  async openQuiz(id?: string) {
    if (!this.shouldOpen && id) {
      let artrange = this.quizService.getArtRange(id);
      const response = await this.quizService.getQuizQuestions(
        artrange.artText
      );
      if (
        !response.question &&
        !response.answer &&
        response.anslistobj.length > 0
      ) {
        throw new Error("exception");
      }
      this.quizQuestions = {
        artigo: artrange.artigo,
        question: response.question,
        anslistobj: response.anslistobj,
        answer: response.answer,
      };
      this.quizArtRange = artrange.artIds;
    } else {
      this.quizArtRange = [];
    }
    this.shouldOpen = !this.shouldOpen;
    this.changeDetector.markForCheck();
  }
  showlines(id: string) {
    let range = this.quizArtRange.findIndex((x) => x == id);
    return range === -1 ? true : false;
  }

  public referencia() {
    const item = this.conteudo.linhas.find(
      (i) => i.id === this.selection.selector.selected[0].idItem
    );
    const referencia = new Referencia();

    referencia.links = [
      {
        idLei: item.idLei,
        idItem: item.id,
      },
    ];

    this.criarReferencia = referencia;
  }

  salvarReferencia(referencia: Referencia) {
    this.usuarioReferenciaService.atualizar(referencia);
    this.criarReferencia = null;
    this.changeDetector.markForCheck();
  }

  editandoReferencia(idLinha: string) {
    if (this.criarReferencia) {
      return (
        this.criarReferencia.links?.find((e) => e.idItem === idLinha)
          ?.idItem === idLinha
      );
    }

    return false;
  }

  public copyText() {
    navigator.clipboard.writeText(window.getSelection().toString());
    this.fecharPopOvers();
  }
}
