import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  fromEvent,
  merge,
  Observable,
  of,
  Subscription,
} from "rxjs";
import { SelectionService, ModoSelecao } from "./selection.service";
import { map, mapTo } from "rxjs/operators";

@Injectable()
export class StatusService {
  private selectedTool: number = null;

  private msgStatusSubject: string = "";
  private msgStatusSubject$ = new BehaviorSubject<string>(
    this.msgStatusSubject
  );

  private appIsOffline$: Subscription = Subscription.EMPTY;
  private appOffline: boolean = false;
  private appOffline$ = new BehaviorSubject<boolean>(this.appOffline);

  private message: string = "";
  private message$ = new BehaviorSubject<string>(this.message);

  private progressoOperacao: number = 0;
  private progressoOperacao$ = new BehaviorSubject<number>(
    this.progressoOperacao
  );

  private mensagensProgresso: ProgressMessageModel[] = [];
  private mensagensProgresso$ = new BehaviorSubject<ProgressMessageModel[]>(
    this.mensagensProgresso
  );

  constructor(private selectionService: SelectionService) {
    this.initialize();
    this.appIsOffline$ = merge(
      of(false),
      fromEvent(window, "online"),
      fromEvent(window, "offline")
    )
      .pipe(map(() => navigator.onLine))
      .subscribe((status) => {
        this.appOffline = !status;
        this.updateAppOffline();
      });
  }

  private initialize() {
    this.selectionService.$Selection.subscribe((sel) => {
      if (sel) {
        this.selectedTool = sel.modo;
      }
      this.voidStatus();
    });
  }

  public async testConnection(): Promise<void> {
    this.updateAppOffline();
  }

  public async updateAppOffline() {
    this.appOffline$.next(this.appOffline);
  }

  public get DataUltimaAtualizacao(): Date {
    return <Date>JSON.parse(localStorage.getItem("DataUltimaAtualizacao"));
  }

  public set DataUltimaAtualizacao(value: Date) {
    localStorage.setItem("DataUltimaAtualizacao", JSON.stringify(value));
  }

  public get PrimeiroDownloadConcluido(): boolean {
    return this.DataUltimaAtualizacao ? true : false;
  }

  public mostrarMensagemProgresso(mensagem: string, tarefa: string) {
    const message = new ProgressMessageModel();
    message.message = mensagem;
    message.task = tarefa;

    const pilha = this.mensagensProgresso$.getValue();
    const iItemPilha = pilha.findIndex((p) => p.task === message.task);

    if (iItemPilha === -1) pilha.push(message);
    else pilha[iItemPilha] = message;

    this.mensagensProgresso$.next(pilha);
  }

  public ocultarMensagemProgresso(tarefa: string) {
    const pilha = this.mensagensProgresso$
      .getValue()
      .filter((i) => i.task !== tarefa);
    this.mensagensProgresso$.next(pilha);
  }

  public setProgressoOperacao(progresso: number = null) {
    this.progressoOperacao$.next(progresso);
  }

  public setMessage(message: string = null) {
    this.message$.next(message);
  }

  public get isAppOnline(): boolean {
    return !this.appOffline$.getValue();
  }

  public getAppOffline(): Observable<boolean> {
    return this.appOffline$.asObservable();
  }

  public getMessage(): Observable<string> {
    return this.message$.asObservable();
  }

  public getMsgStatusSubject(): Observable<string> {
    return this.msgStatusSubject$.asObservable();
  }

  public getProgressoOperacao(): Observable<number> {
    return this.progressoOperacao$.asObservable();
  }

  public getMensagensProgresso(): Observable<ProgressMessageModel[]> {
    return this.mensagensProgresso$.asObservable();
  }

  public voidStatus() {
    this.msgStatusSubject$.next(this.getToolDescription(this.selectedTool));
  }

  private getToolDescription(modo: ModoSelecao): string {
    switch (modo) {
      case ModoSelecao.Padrao:
        return "Modo de seleção";
      case ModoSelecao.Marcacao:
        return "Modo marcação";
      case ModoSelecao.MarcaTexto:
        return "Modo marca-texto";
      case ModoSelecao.Borracha:
        return "Modo borracha";
    }
  }

  public setStatusMessage(message: string, timeout: boolean = false) {
    this.msgStatusSubject$.next(message);

    if (timeout) {
      setTimeout(() => {
        this.voidStatus();
      }, 2500);
    }
  }
}

export class ProgressMessageModel {
  task: string;
  message: string;
}
