import { Component, OnInit, OnDestroy } from "@angular/core";
import { StatusService } from "../../../services/status.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-info-panel",
  templateUrl: "./info-panel.component.html",
  styleUrls: ["./info-panel.component.scss"],
})
export class InfoPanelComponent implements OnInit, OnDestroy {
  private s_msgStatusObservable: Subscription;

  public currentStatus: string = null;

  constructor(private statusService: StatusService) {}

  ngOnInit() {
    this.s_msgStatusObservable = this.statusService
      .getMsgStatusSubject()
      .subscribe((msg: string) => {
        setTimeout(() => {
          this.currentStatus = msg;
        });
      });
  }

  ngOnDestroy(): void {
    this.s_msgStatusObservable.unsubscribe();
  }
}
