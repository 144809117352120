import { Component, OnInit, OnDestroy } from "@angular/core";

@Component({
  selector: "app-leitor-content",
  templateUrl: "./leitor-content.component.html",
  styleUrls: ["./leitor-content.component.scss"],
})
export class LeitorContentComponent implements OnInit, OnDestroy {
  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
