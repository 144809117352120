import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, Subscription } from "rxjs";
import { EntitiesHelper } from "../helpers/entities.helper";
import { Guia } from "../models/Guia";
import { TimerFunctions } from "../components/timer/timer.functions";
import { UsuarioGuiasService } from "./data-services/usuario.guias.service";
import { UsuarioApontamentosService } from "./data-services/usuario.apontamentos.service";
import { BaseObj } from "../models/UserdataSync";
import { StringHelper } from "../helpers/string.helper";
import { LoggingService } from "./logging.service";

@Injectable()
export class TimerService implements OnDestroy {
  private s_guias: Subscription;
  private s_anotacoes: Subscription;

  private idLeiGuiaAtiva: string;

  public $apontamentos: Observable<Array<Apontamento>>;
  private apontamentos = new BehaviorSubject<Array<Apontamento>>(null);

  public $apontamentoAtual: Observable<Apontamento>;
  private apontamentoAtual = new BehaviorSubject<Apontamento>(null);
  anotacoes: Apontamento[];

  constructor(
    private usuarioGuiaService: UsuarioGuiasService,
    private usuarioApontamentosService: UsuarioApontamentosService,
    private logginService: LoggingService
  ) {
    this.$apontamentos = this.apontamentos.asObservable();
    this.$apontamentoAtual = this.apontamentoAtual.asObservable();

    this.s_guias = this.usuarioGuiaService
      .getGuias()
      .subscribe((g) => this.guias_subscribe(g));

    this.carregarApontamentos();
  }

  carregarApontamentos() {
    const hoje = new Date();
    this.usuarioApontamentosService.buscar().then((aps) => {
      this.anotacoes = aps.filter((ap) => ap.controleTempo.length > 0);
      this.refresh();
    });
  }

  private refresh(): void {
    this.apontamentos.next(this.anotacoes);
  }

  public ngOnDestroy(): void {
    this.s_guias.unsubscribe();
    this.s_anotacoes.unsubscribe();
  }

  private guias_subscribe(guias: Guia[]) {
    if (!guias) {
      return;
    }

    const guiaAtiva = guias.find((g) => g.ativa);

    if (guiaAtiva && guiaAtiva.idLei !== this.idLeiGuiaAtiva) {
      let resumir = false;
      const apAtual = this.apontamentoAtual.value;

      if (apAtual && apAtual.ativo) {
        this.pausarApontamento();
        resumir = true;
      }

      if (guiaAtiva.idLei) {
        const hoje = new Date();
        const dia = hoje.getDate();
        const mes = hoje.getMonth() + 1;
        const ano = hoje.getFullYear();

        this.buscarApontamento(guiaAtiva.idLei, dia, mes, ano).then((ap) => {
          if (!ap) {
            ap = new Apontamento();
            ap.idLei = guiaAtiva.idLei;

            this.salvarApontamento(ap).then(() => {
              this.carregarApontamento(ap);

              if (resumir) {
                this.resumirApontamento();
              }
            });
          } else {
            this.carregarApontamento(ap);

            if (resumir) {
              this.resumirApontamento();
            }
          }
        });
      } else {
        this.apontamentoAtual.next(null);
      }

      this.idLeiGuiaAtiva = guiaAtiva.idLei;
    }
  }

  public resumirApontamento(): any {
    const ap = this.apontamentoAtual.value;
    const intervalo = new TempoApontamento();
    intervalo.inicio = new Date().getTime();
    ap.controleTempo.push(intervalo);

    this.salvarApontamento(ap).then(() => {
      this.carregarApontamento(ap);
    });

    this.logginService.LogEvent("Timer Start", null, null);
  }

  public pausarApontamento(): any {
    const ap = this.apontamentoAtual.value;
    ap.controleTempo.find((c) => !c.termino).termino = new Date().getTime();

    this.salvarApontamento(ap).then(() => {
      this.carregarApontamento(ap);
    });

    this.logginService.LogEvent("Timer Pause", null, null);
  }

  public incluirAnotacao(texto: string): any {
    const ap = this.apontamentoAtual.value;
    ap.anotacoes[0] = texto;

    this.salvarApontamento(ap).then(() => {
      this.carregarApontamento(ap);
    });
  }

  private salvarApontamento(ap: Apontamento): Promise<void> {
    return new Promise((onsuccess) => {
      this.usuarioApontamentosService.atualizar(ap).then(() => {
        onsuccess();
      });
    });
  }

  private buscarApontamento(idLei, dia, mes, ano): Promise<Apontamento> {
    return new Promise((onsuccess) => {
      this.usuarioApontamentosService.buscar(idLei).then((apontamentos) => {
        const apontamento = apontamentos.find(
          (ap) => ap.dia === dia && ap.mes === mes && ap.ano === ano
        );

        onsuccess(apontamento);
      });
    });
  }

  private carregarApontamento(ap: Apontamento): void {
    const obj = Apontamento.fromJSON(JSON.stringify(ap));
    this.apontamentoAtual.next(obj);
    this.carregarApontamentos();
  }
}

export class Apontamento extends BaseObj {
  public id: string;

  public dia: number;
  public mes: number;
  public ano: number;

  public idConjunto: string;
  public idLei: string;

  public anotacoes: string[];
  public controleTempo: TempoApontamento[];
  public descricaoConteudo: string;

  public constructor() {
    super();

    this.id = EntitiesHelper.generateGuid();

    this.anotacoes = new Array<string>();
    this.controleTempo = new Array<TempoApontamento>();

    const hoje = new Date();
    this.dia = hoje.getDate();
    this.mes = hoje.getMonth() + 1;
    this.ano = hoje.getFullYear();
  }

  get ativo(): boolean {
    return this.controleTempo.filter((ct) => !ct.termino).length > 0;
  }

  get tempoTotalSegundos(): number {
    let mili = 0;
    let segundos = 0;

    this.controleTempo.forEach((controle) => {
      const dataTermino = controle.termino
        ? controle.termino
        : new Date().getTime();
      mili += dataTermino - controle.inicio;
    });

    if (mili) {
      segundos = Math.floor(mili / 1000);
    }

    return segundos;
  }

  get strTempoTotal(): string {
    return TimerFunctions.toTimeString(this.tempoTotalSegundos);
  }

  public static fromJSON(json: string): Apontamento {
    const ret = new Apontamento();
    const obj = JSON.parse(json);

    for (let prop in obj) ret[prop] = obj[prop];

    return ret;
  }

  public static CheckIfActive(apontamento: Apontamento): boolean {
    return false;
  }
}

export class TempoApontamento {
  public inicio: number;
  public termino: number;
}
