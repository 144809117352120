import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { ItemVersaoRangeSelecao } from "../models/ItemVersaoRangeSelecao";

@Injectable()
export class SelectionService {
  public $Selection: Observable<Selection>;
  private _Selection = new BehaviorSubject<Selection>(null);

  public get selection() {
    return this._Selection.getValue();
  }

  constructor() {
    this.$Selection = this._Selection.asObservable();
  }

  atualizar(selection: Selection) {
    this._Selection.next(selection);
  }
}

export class Selection {
  modo: ModoSelecao;
  tipo: TipoSelecao;
  selector: Selector;

  constructor() {
    this.modo = ModoSelecao.Padrao;
    this.tipo = null;
  }
}

export class Selector {
  start: number;
  end: number;
  selected: Array<ItemVersaoRangeSelecao>;
  selectedSourceId: string;
  idConjunto: string;
  idLei: string;
  clientX: number;
}

export enum ModoSelecao {
  Padrao = 0,
  Marcacao = 1,
  MarcaTexto = 2,
  Borracha = 3,
}

export enum TipoSelecao {
  // CaiuProva = 0,
  // ErrouProva = 1,
  // Importante = 2,
  // Dificil = 3,
  // Comentario = 4,
  // Caneta1 = 5,
  // Caneta2 = 6,
  // Caneta3 = 7,
  // Caneta4 = 8,
  // Caneta5 = 9

  Prova = 0,
  Comentario = 1,
  Mnemonico = 2,
  Caneta1 = 3,
  Caneta2 = 4,
  Caneta3 = 5,
  Caneta4 = 6,
  Caneta5 = 7,
}
