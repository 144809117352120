<div class="toolbar-placeholder" *ngIf="visivel">
  <app-btn-toolbar
    (click)="btnBusca_click()"
    [icone]="'busca'"
    [ativo]="painelBuscaAberto"
    [cor]="'#919191'"
    [texto]="'Busca'"
  ></app-btn-toolbar>
  <app-btn-toolbar
    (click)="toggle(opcao)"
    [icone]="opcao.icone"
    [ativo]="
      opcaoSelecionada.modo === opcao.modo &&
      opcaoSelecionada.tipo === opcao.tipo
    "
    [cor]="opcao.cor"
    [texto]="opcao.descricao"
    *ngFor="let opcao of opcoesExibir"
  ></app-btn-toolbar>
</div>
