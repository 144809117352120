import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, Subscription } from "rxjs";
import { StringHelper } from "src/app/helpers/string.helper";
import { Guia } from "src/app/models/Guia";
import {
  ItemLookupOrdenacaoNovaGuia,
  TipoOrdenacaoNovaGuia,
} from "src/app/models/lei/item.lookup.ordenacao.nova.guia";
import { LeiLookup } from "src/app/models/lei/lei.lookup";
import { EstatisticasLeitura } from "src/app/models/usuario/EstatisticasLeitura";
import { LeiRepositorio } from "src/app/repositorios/lei.repositorio";
import { UsuarioEstatisticasService } from "src/app/services/data-services/usuario.estatisticas.service";
import { UsuarioGuiasService } from "src/app/services/data-services/usuario.guias.service";
import { UiService } from "src/app/services/ui.service";

@Injectable({
  providedIn: "root",
})
export class NavegacaoPesquisaLeisService {
  private leisLookupFiltrada: LeiLookup[] = [];
  private leisLookup: LeiLookup[] = [];
  private leisLookup$ = new BehaviorSubject<LeiLookup[]>(this.leisLookup);

  private filtrarFavoritos: boolean = false;
  private filtrarFavoritos$ = new BehaviorSubject<boolean>(
    this.filtrarFavoritos
  );

  private textoBusca: string = "";
  private textoBusca$ = new BehaviorSubject<string>(this.textoBusca);

  private pagina: Set<number> = new Set<number>();
  private pagina$ = new BehaviorSubject<Set<number>>(this.pagina);

  private paginaPesquisa: Set<number> = new Set<number>();
  private paginaPesquisa$ = new BehaviorSubject<Set<number>>(
    this.paginaPesquisa
  );

  private exibirNavegacaoPesquisaMobile: boolean = false;
  private exibirNavegacaoPesquisaMobile$ = new BehaviorSubject<boolean>(
    this.exibirNavegacaoPesquisaMobile
  );

  private modoOrdenacao: ItemLookupOrdenacaoNovaGuia = ModosOrdenacao[3];
  private modoOrdenacao$ = new BehaviorSubject<ItemLookupOrdenacaoNovaGuia>(
    this.modoOrdenacao
  );

  private eventosLoading: string[] = [];
  private eventosLoading$ = new BehaviorSubject<boolean>(
    this.eventosLoading.length > 0
  );

  private subscriptions: Subscription[] = [];

  constructor(
    private leiRepositorio: LeiRepositorio,
    private usuarioEstatisticasService: UsuarioEstatisticasService,
    private usuarioGuiasService: UsuarioGuiasService,
    public uiService: UiService
  ) {
    this.subscribeToObservables();
    this.LeisPaginada();
  }

  subscribeToObservables() {
    this.subscriptions.forEach((sub) => sub.unsubscribe);
    this.subscriptions = [];

    //Sempre que guia é alterada, é alterada duas vezes
    this.subscriptions.push(
      this.usuarioGuiasService
        .getGuiaAtiva()
        .subscribe((guia) => this.guiaAtiva_changed(guia))
    );
    this.subscriptions.push(
      this.usuarioEstatisticasService.Favoritos.subscribe((fav) =>
        this.favoritos_changed(fav)
      )
    );
    this.subscriptions.push(
      this.uiService.getMobile().subscribe((mobile) => {
        if (this.exibirNavegacaoPesquisaMobile && !mobile) {
          this.updateExibirNavegacaoPesquisaMobile();
        }
      })
    );
  }

  private alterarCarregando(carregando, nomeEvento) {
    if (carregando) {
      this.eventosLoading = this.eventosLoading
        ? this.eventosLoading
        : new Array<string>();
      this.eventosLoading.push(nomeEvento);
      this.updateEventosLoading();
    } else {
      let i = this.eventosLoading.findIndex((x) => x == nomeEvento);
      this.eventosLoading.splice(i, 1);
      this.updateEventosLoading();
    }
  }
  async LeisPaginada() {
    if (!this.pagina.has(-1) && this.pagina.has(this.pagina.size)) {
      return;
    }
    this.addProximaPagin();
    const lei = await this.leiRepositorio.carregarLeisPaginada(
      this.pagina.size - 1
    );
    if (lei.length == 0) {
      this.pagina.add(-1);
      this.updatePagina();
    }
    this.leisLookup = Object.values(
      [...this.leisLookup, ...lei].reduce(
        (acc, obj) => ({ ...acc, [obj.id]: obj }),
        {}
      )
    );
    this.carregarLeis();
  }

  async LeisPesquisaPaginada() {
    if (
      !this.pagina.has(-1) &&
      this.paginaPesquisa.has(this.paginaPesquisa.size)
    ) {
      return;
    }
    this.addProximaPaginPesquisa();
    const lei = await this.leiRepositorio.carregarLeisPesquisaPaginada(
      this.paginaPesquisa.size - 1,
      this.textoBusca
    );
    if (lei.length == 0) {
      this.paginaPesquisa.add(-1);
      this.updatePaginaPesquisa();
    }
    this.leisLookup = Object.values(
      [...this.leisLookup, ...lei].reduce(
        (acc, obj) => ({ ...acc, [obj.id]: obj }),
        {}
      )
    );

    this.carregarLeis();
  }

  public async carregarLeis() {
    const estatisticas = await this.usuarioEstatisticasService.listar();
    for (const lei of this.leisLookup) {
      lei.estatisticas = estatisticas.find((e) => e.id === lei.id);
      if (!lei.estatisticas) {
        const estatistica = new EstatisticasLeitura();
        estatistica.id = lei.id.toString();

        await this.usuarioEstatisticasService.salvar(estatistica);
        lei.estatisticas = estatistica;
      }

      lei.progresso = EstatisticasLeitura.CalcularProgresso(
        lei.quantidadeItens,
        lei.estatisticas.linhasLidas.length
      );
      lei.favorita = lei.estatisticas.favorito;
    }

    this.aplicarFiltro();
  }

  public aplicarFiltro() {
    this.leisLookupFiltrada = this.leisLookup;

    if (!this.leisLookupFiltrada || this.leisLookupFiltrada.length == 0) return;

    if (this.filtrarFavoritos)
      this.leisLookupFiltrada = this.leisLookupFiltrada.filter(
        (l) => l.favorita
      );

    if (this.textoBusca) {
      const query = StringHelper.RemoverAcentosCaracteresEspeciais(
        this.textoBusca
      ).toLowerCase();
      const words = query.split(" ");

      this.leisLookupFiltrada = this.leisLookupFiltrada.filter((l) => {
        let matches = 0;

        words.forEach((word) => {
          const tituloLei = StringHelper.RemoverAcentosCaracteresEspeciais(
            l.titulo
          ).toLowerCase();
          if (tituloLei.indexOf(word) !== -1) matches++;
        });

        return matches == words.length;
      });
    }

    switch (this.modoOrdenacao.ordenacao) {
      case TipoOrdenacaoNovaGuia.OrdemAlfabetica:
        this.leisLookupFiltrada = this.leisLookupFiltrada.sort((a1, a2) =>
          StringHelper.AlphabeticnaturalSort(a1.titulo, a2.titulo)
        );
        break;
      case TipoOrdenacaoNovaGuia.MaisLidos:
        this.leisLookupFiltrada = this.leisLookupFiltrada.sort(
          (a1, a2) => a2.progresso - a1.progresso
        );
        break;
      case TipoOrdenacaoNovaGuia.MenosLidos:
        this.leisLookupFiltrada = this.leisLookupFiltrada.sort(
          (a1, a2) => a1.progresso - a2.progresso
        );
        break;
      case TipoOrdenacaoNovaGuia.DataAlteracao:
        this.leisLookupFiltrada = this.leisLookupFiltrada.sort(
          (a1, a2) =>
            new Date(a2.dataHoraUltimaModificacaoOficial).getTime() -
            new Date(a1.dataHoraUltimaModificacaoOficial).getTime()
        );
        break;
    }

    this.updateLeisLookup();
  }

  async alterarModoOrdenacao(novoModo: ItemLookupOrdenacaoNovaGuia) {
    let nomeEvento = "modoOrdenacao" + Date.now().toString();
    this.alterarCarregando(true, nomeEvento);

    this.modoOrdenacao = novoModo;
    this.updateModoOrdenacao();
    await this.aplicarFiltro();

    this.alterarCarregando(false, nomeEvento);
  }

  async favoritos_toggle() {
    let nomeEvento = "favoritos_togle" + Date.now().toString();
    this.alterarCarregando(true, nomeEvento);

    this.filtrarFavoritos = !this.filtrarFavoritos;

    this.updateFiltrarFavoritos();

    await this.aplicarFiltro();

    this.alterarCarregando(false, nomeEvento);
  }

  async alterarTextoBusca(txtBusca: string) {
    let nomeEvento = "textoBusca" + Date.now().toString();

    this.alterarCarregando(true, nomeEvento);
    if (txtBusca.length == 0) {
      this.paginaPesquisa.clear();
      this.updatePaginaPesquisa();
    }

    this.textoBusca = txtBusca;
    this.updateTextoBusca();

    if (txtBusca.length >= 3) {
      this.LeisPesquisaPaginada();
    }

    await this.aplicarFiltro();

    this.alterarCarregando(false, nomeEvento);
  }

  favoritos_changed(fav: string[]): void {
    let nomeEvento = "favoritosChange" + Date.now().toString();
    this.alterarCarregando(true, nomeEvento);

    this.leisLookup.forEach((lei) => {
      lei.favorita = fav.indexOf(lei.id) !== -1;
    });

    this.aplicarFiltro();

    this.alterarCarregando(false, nomeEvento);
  }

  guiaAtiva_changed(guia: Guia): void {
    let nomeEvento = "guiaChange" + Date.now().toString();
    this.alterarCarregando(true, nomeEvento);

    this.alterarCarregando(false, nomeEvento);
  }

  updateLeisLookup() {
    this.leisLookup$.next(this.leisLookupFiltrada);
  }

  getLeisLookup(): Observable<LeiLookup[]> {
    return this.leisLookup$.asObservable();
  }

  updateFiltrarFavoritos() {
    this.filtrarFavoritos$.next(this.filtrarFavoritos);
  }

  getFiltrarFavoritos(): Observable<boolean> {
    return this.filtrarFavoritos$.asObservable();
  }

  updateTextoBusca() {
    this.textoBusca$.next(this.textoBusca);
  }

  getTextoBusca(): Observable<string> {
    return this.textoBusca$.asObservable();
  }

  updateModoOrdenacao() {
    this.modoOrdenacao$.next(this.modoOrdenacao);
  }

  getModoOrdenacao(): Observable<ItemLookupOrdenacaoNovaGuia> {
    return this.modoOrdenacao$.asObservable();
  }

  updateEventosLoading() {
    this.eventosLoading$.next(this.eventosLoading.length > 0);
  }

  getEventosLoading(): Observable<boolean> {
    return this.eventosLoading$.asObservable();
  }
  updatePagina() {
    this.pagina$.next(this.pagina);
  }

  getPagina(): Observable<Set<number>> {
    return this.pagina$.asObservable();
  }
  get valuePagina(): Set<number> {
    return this.pagina;
  }

  addProximaPagin() {
    if (this.pagina.size == 0) {
      this.pagina.add(0);
    } else {
      this.pagina.add(this.pagina.size);
    }

    this.updatePagina();
  }

  updatePaginaPesquisa() {
    this.paginaPesquisa$.next(this.paginaPesquisa);
  }

  getPaginaPesquisa(): Observable<Set<number>> {
    return this.paginaPesquisa$.asObservable();
  }

  get valuePaginaPesquisa(): Set<number> {
    return this.paginaPesquisa;
  }

  addProximaPaginPesquisa() {
    if (this.paginaPesquisa.size == 0) {
      this.paginaPesquisa.add(0);
    } else {
      this.paginaPesquisa.add(this.paginaPesquisa.size);
    }
    this.updatePaginaPesquisa();
  }

  updateExibirNavegacaoPesquisaMobile() {
    this.exibirNavegacaoPesquisaMobile = !this.exibirNavegacaoPesquisaMobile;
    this.exibirNavegacaoPesquisaMobile$.next(
      this.exibirNavegacaoPesquisaMobile
    );
  }

  getExibirNavegacaoPesquisaMobile(): Observable<boolean> {
    return this.exibirNavegacaoPesquisaMobile$.asObservable();
  }
}

export const ModosOrdenacao: ItemLookupOrdenacaoNovaGuia[] = [
  {
    label: "Ordem alfabética",
    ordenacao: TipoOrdenacaoNovaGuia.OrdemAlfabetica,
  },
  { label: "Mais lidos", ordenacao: TipoOrdenacaoNovaGuia.MaisLidos },
  { label: "Menos lidos", ordenacao: TipoOrdenacaoNovaGuia.MenosLidos },
  {
    label: "Data de alteração",
    ordenacao: TipoOrdenacaoNovaGuia.DataAlteracao,
  },
];
