import * as firebase from "firebase/app";
import "firebase/analytics";

import { FirebaseApp } from "@angular/fire";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable()
export class LoggingService {
  constructor(private app: FirebaseApp) {}

  /**
   * Loggar eventos e dados via google analytics
   * @param evento Nome do evento a ser logado ex: Carregou pagina x
   * @param key Nome da informação adicional que será passada ex: email (!IMPORTANTE: o parâmetro deve ser adicionado ao relatório de parâmetros do evento no google analytics para ser exibido)
   * @param description Informação adicional
   */
  public LogEvent(evento: string, key: string, description: string) {
    try {
      firebase
        .analytics()
        .logEvent(evento, { key: key, description: description });
    } catch (error) {
      console.error(error);
    }
  }
}
