import { Injectable } from "../../../../node_modules/@angular/core";
import { Observable, BehaviorSubject } from "../../../../node_modules/rxjs";
import { Injectable as Injectable_1 } from "@angular/core";

@Injectable_1()
@Injectable()
export class ItensVerificarService {
  public itens: Observable<ItemVerificar[]>;
  private _itens = new BehaviorSubject<ItemVerificar[]>(this.carregarItens());

  constructor() {
    this.itens = this._itens.asObservable();
  }

  public get Itens(): ItemVerificar[] {
    if (!this._itens.value) {
      this.Itens = this.carregarItens();
    }

    return this._itens.value;
  }

  public set Itens(value: ItemVerificar[]) {
    localStorage.setItem("itens-verificar", JSON.stringify(value));
    this._itens.next(value);
  }

  private carregarItens(): ItemVerificar[] {
    return JSON.parse(localStorage.getItem("itens-verificar"));
  }
}

export class ItemVerificar {
  public id: string;
  public tipoItem: TipoItem;
}

export enum TipoItem {
  Marcacao = 0,
  Comentario = 1,
  Grifo = 2,
}
