import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { BehaviorSubject } from "rxjs";
import { TipoSelecao } from "../../services/selection.service";

export interface RevisaoAnotacao {
  descricaoDocumento: string;
  idConjunto: string;
  idLei: string;
  idItem: string;
  tituloGuia: string;
  icone: string;
  cor: string;
  tipoMarcacao: TipoSelecao;
  descricaoMarcacao: string;
}

@Injectable()
export class NovidadesService {
  public itensRevisaoAnotacao: Observable<RevisaoAnotacao[]>;
  public itensRevisaoAnotacaoSubject: BehaviorSubject<RevisaoAnotacao[]> =
    new BehaviorSubject<RevisaoAnotacao[]>(null);

  constructor() {
    this.itensRevisaoAnotacao = this.itensRevisaoAnotacaoSubject.asObservable();
    console.log("TODO: Verificar o funcionamento das notificações de revisão");
  }
}
