import { Injectable, ErrorHandler, OnDestroy } from "@angular/core";
import { ErrorLog } from "../models/error/errorLog";
import { ErrorLogRepositorio } from "../repositorios/errorLog.repositorio";
import { LoggingService } from "./logging.service";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { catchError, retry } from "rxjs/operators";
import { StatusService } from "./status.service";
import { Subscription } from "rxjs";
import { ApplicationInsightsService } from "./application-insights.service";
import { IErrorService } from "@microsoft/applicationinsights-angularplugin-js";

@Injectable()
export class ErrorHandlerService implements OnDestroy, IErrorService {
  private subs = Array<Subscription>();

  constructor(
    private errorLogRepositorio: ErrorLogRepositorio,
    private loggingService: LoggingService,
    private httpClient: HttpClient,
    private statusService: StatusService,
    private applicationInsightsService: ApplicationInsightsService
  ) {
    this.subs.push(
      this.errorLogRepositorio.$RepositorioOk.subscribe((repositorioOk) => {
        if (repositorioOk === true) this.syncPendentes();
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe);
  }

  /**
   * Gravar erro localmente e sincronizar com apis de log
   * @param error Objeto de erro
   * @param mensagemAdicional Mensagem adicionada ao erro, podendo conter nome do componente ou método que ocasionou erro
   * @param parametrosAdicionais Parametros adicionados manualmente para coleta de dados em um erro
   */
  // public async handleError(
  //   error: any,
  //   mensagemAdicional: string = "",
  //   parametrosAdicionais: Array<ErrorLogParameters> = new Array<ErrorLogParameters>()
  // ): Promise<void> {
  //   try {
  //     if (
  //       error.message &&
  //       (error.message.indexOf("IndexedDB error:") > -1 ||
  //         error.message.indexOf(
  //           "Failed to execute 'transaction' on 'IDBDatabase': One of the specified object stores was not found"
  //         ) > -1 ||
  //         error.message.indexOf(
  //           "Unable to delete database because it's blocked"
  //         ) > -1)
  //     ) {
  //       this.logoff();
  //       return;
  //     }

  //     let stack;
  //     if (error.error && error.error instanceof ErrorEvent) {
  //       mensagemAdicional =
  //         mensagemAdicional && mensagemAdicional.length > 0
  //           ? mensagemAdicional + " - " + error.error.message
  //           : error.error.message;
  //       stack = error.error.stack;
  //     } else {
  //       mensagemAdicional =
  //         mensagemAdicional && mensagemAdicional.length > 0
  //           ? mensagemAdicional + " - " + error.message
  //           : error.message;
  //       stack = error.stack;
  //     }

  //     let errorLog = new ErrorLog();
  //     errorLog.dataHora = new Date();
  //     errorLog.mensagem = mensagemAdicional;
  //     errorLog.stackTrace = stack;
  //     errorLog.emailUsuario =
  //       this.userService && this.userService.Configuracoes
  //         ? this.userService.Configuracoes.email
  //         : "";
  //     errorLog.sincronizadoGoogleAnalytics = false;
  //     errorLog.sincronizadoWebApi = false;
  //     errorLog.parametrosAdicionais = parametrosAdicionais;

  //     //Salvar recuperando id gerado automaticamente
  //     errorLog.clientId = await this.errorLogRepositorio.salvar(errorLog);
  //     console.error("OCORREU UM ERRO: " + JSON.stringify(errorLog));

  //     this.syncError(errorLog);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  private logoff() {
    setTimeout(() => {
      window.location.replace("/logoff?clear=true");
    });
  }

  private async syncError(errorLog: ErrorLog) {
    try {
      if (!this.statusService.isAppOnline) {
        return;
      }

      if (!errorLog.sincronizadoGoogleAnalytics) {
        this.loggingService.LogEvent(
          "Erro",
          "error_object",
          JSON.stringify(errorLog)
        );
        errorLog.sincronizadoGoogleAnalytics = true;
        await this.errorLogRepositorio.atualizar(errorLog);
      }

      if (!errorLog.sincronizadoWebApi) {
        const url = `${AppConfig.logApiEndpoint}/ErrorLogs/Create`;
        this.httpClient
          .post(url, {
            mensagem: errorLog.mensagem,
            stackTrace: errorLog.stackTrace,
            emailUsuario: errorLog.emailUsuario,
            dataHora: errorLog.dataHora,
            parametrosAdicionais: errorLog.parametrosAdicionais,
          })
          .pipe(
            retry(2),
            catchError((error) => {
              throw error;
            })
          )
          .subscribe((logResponse) => {
            errorLog.sincronizadoWebApi = true;
            this.errorLogRepositorio.atualizar(errorLog);
          });
      }
    } catch (error) {
      //Não realizar nada pois ja é trativa de erro
      console.log(
        "Não foi possível sincronizar erros. Nova tentativa será realizada em alguns minutos"
      );
    }
  }

  private async syncPendentes() {
    try {
      if (window.location.href.toLowerCase().indexOf("logoff") > -1) {
        return;
      }

      const todos = await this.errorLogRepositorio.buscarTodos();
      const pendentes = todos.filter(
        (x) => !x.sincronizadoWebApi || !x.sincronizadoGoogleAnalytics
      );
      pendentes.forEach(async (error) => {
        await this.syncError(error);
      });

      if (todos.length > 4000) {
        this.errorLogRepositorio.excluirTodos();
      }
    } catch (error) {
      console.error(
        "Não foi possível sincronizar erros. Nova tentativa será realizada em alguns minutos"
      );
    }
  }
  //ApplicationInsights
  handleError(error: any) {
    error.message = `App-SVM-Error: ${error?.message}`;
    this.applicationInsightsService.logException(error);
  }
}
