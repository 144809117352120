import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { ConteudoService } from "../../services/conteudo.service";
import { Conteudo } from "../../models/pagina/conteudo";
import { Subscription } from "rxjs";
import { BuscaPanelParameters } from "../leitor-content-panelbusca/busca-panel.parameters";
import { BuscaService } from "src/app/services/busca.service";
import { UsuarioPreferenciasService } from "src/app/services/data-services/usuario.preferencias.service";
@Component({
  selector: "app-leitor-content-tabcontent",
  templateUrl: "./leitor-content-tabcontent.component.html",
  styleUrls: ["./leitor-content-tabcontent.component.scss"],
})
export class LeitorContentTabcontentComponent implements OnInit, OnDestroy {
  public mostrarNovaGuia: boolean;
  public mostrarPainelBusca: boolean;
  public mostrarResultadoBuscaAvancada: boolean;
  private subscriptions: Subscription[] = [];

  constructor(
    private conteudoService: ConteudoService,
    private buscaService: BuscaService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.conteudoService.Conteudo.subscribe((conteudo) =>
        this.conteudo_changed(conteudo)
      )
    );
    this.subscriptions.push(
      this.buscaService
        .getBusca()
        .subscribe((busca) => this.busca_changed(busca))
    );
  }

  conteudo_changed(conteudo: Conteudo): void {
    if (conteudo) {
      this.mostrarNovaGuia = conteudo.novaGuia;
    }
  }

  busca_changed(params: BuscaPanelParameters): void {
    this.mostrarPainelBusca = params ? true : false;
    this.mostrarResultadoBuscaAvancada =
      params && !params.buscaSemResultado && params.buscarTodosDocumentos;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = [];
  }
}
