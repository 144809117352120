import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { Guia } from "../../models/Guia";
import { IUsesStorageHelper } from "../../interfaces/IUsesStorageHelper";
import { StorageHelper } from "../../helpers/storage.helper";
import {
  OperacaoSignalR,
  SignalrService,
  EnumTipoObjeto,
} from "../signalr.service";
import { StatusService } from "../status.service";
import { LeiLookup } from "../../models/lei/lei.lookup";
import { ErrorHandlerService } from "../errorHandler.service";

@Injectable()
export class UsuarioGuiasService implements IUsesStorageHelper {
  readonly databaseName = "userdata";
  readonly collectionName = "guias";

  private guias: Guia[] = [];
  private guias$ = new BehaviorSubject<Guia[]>(this.guias);

  private guiaAtiva: Guia = null;
  private guiaAtiva$ = new BehaviorSubject<Guia>(this.guiaAtiva);

  public indexGuiaAtiva$ = this.guias$.pipe(
    map((items) => items.findIndex((g) => g.ativa))
  );

  public indexMouseOver$ = this.guias$.pipe(
    map((items) => items.findIndex((g) => g.hover))
  );

  public indexGuia$ = this.guias$.pipe(
    map((items) => items.findIndex((g) => g.ativa))
  );

  constructor(
    private errorHandlerService: ErrorHandlerService,
    private signalrService: SignalrService,
    private statusService: StatusService
  ) {
    this.carregar();
  }

  public async fromNuvem(guias: Guia[]): Promise<void> {
    guias = await StorageHelper.upsertMany(
      guias,
      this.databaseName,
      this.collectionName,
      false
    );
  }

  public async carregar(): Promise<void> {
    if (this.guias.length == 0) {
      this.guias = await this.listar();
    }

    let commit = false;

    if (!this.guias || this.guias.length === 0) {
      this.guias.push(this.criarGuia());
      commit = true;
    }

    if (this.guias.findIndex((g) => g.ativa) === -1) {
      this.guias[0].ativa = true;
      commit = true;
    }

    if (this.guias.filter((g) => g.ativa).length >= 2) {
      const iProximaGuia = this.guias.findIndex((g) => g.ativa);
      for (const guia of this.guias) guia.ativa = false;

      this.guias[iProximaGuia].ativa = true;
      commit = true;
    }
    if (commit) this.atualizarVarias();

    this.updateGuias();
    this.guiaAtiva = this.guias.find((g) => g.ativa);
    this.updateGuiaAtiva();
  }

  private async listar(): Promise<Guia[]> {
    try {
      const guias = await StorageHelper.list<Guia>(
        this.databaseName,
        this.collectionName
      );
      if (!guias) {
        return [];
      } else {
        return guias.filter((g) => !g.removido).sort((g) => g.ordem);
      }
    } catch (err) {
      this.errorHandlerService.handleError(err);
      throw err;
    }
  }

  public async buscar(id: string): Promise<Guia> {
    try {
      const guia = await StorageHelper.getByKey<Guia>(
        id,
        this.databaseName,
        this.collectionName
      );
      return guia;
    } catch (err) {
      this.errorHandlerService.handleError(err);
      throw err;
    }
  }

  public async atualizar(guia: Guia, sync = true): Promise<Guia> {
    const guiaAtualizada = await StorageHelper.upsert(
      guia,
      this.databaseName,
      this.collectionName
    );
    if (sync) {
      const mensagem = new OperacaoSignalR();
      mensagem.dados = guia;
      this.signalrService.enviarMensagem(mensagem, EnumTipoObjeto.Guias);
    }
    let index = this.guias.findIndex((g) => g.id == guia.id);
    if (index == -1) {
      this.guias.push(guia);
    } else {
      this.guias[index] = guia;
    }

    this.updateGuias();

    return guiaAtualizada;
  }

  public async atualizarVarias(sync = true): Promise<Guia[]> {
    const guiasAtualizada = await StorageHelper.upsertMany(
      this.guias,
      this.databaseName,
      this.collectionName
    );
    if (sync) {
      const mensagens = this.guias.map((g) => {
        const msg = new OperacaoSignalR();
        msg.dados = g;
        return msg;
      });

      this.signalrService.enviarMensagens(mensagens, EnumTipoObjeto.Guias);
    }
    this.updateGuias();

    return guiasAtualizada;
  }
  public async alterarConteudoGuia(id: string, item: LeiLookup): Promise<void> {
    const guiaExiste = this.guias.find((g) => g.idLei == item.id);
    if (guiaExiste) {
      this.alterarGuiaAtiva(guiaExiste.id);
      return;
    }
    let guia = this.guias.find((g) => g.id == id);

    guia.idLei = item.id;
    guia.titulo = item.titulo;

    await this.atualizar(guia);
    await this.carregar();
  }

  private criarGuia(): Guia {
    return new Guia();
  }

  public async fecharGuia(id: string): Promise<void> {
    const iGuiaRemover = this.guias.findIndex((g) => g.id === id);

    this.guias[iGuiaRemover].removido = true;

    await this.atualizarVarias();

    this.guias = this.guias.filter((g) => g.removido != true);

    await StorageHelper.delete(this.databaseName, this.collectionName, id);

    await this.carregar();
  }

  public async novaGuia(guiaNova: Guia): Promise<Guia> {
    try {
      const guiaExiste = this.guias.find((g) => g.idLei == guiaNova.idLei);
      if (guiaExiste) {
        this.alterarGuiaAtiva(guiaExiste.id);
        return;
      }

      const guiaSalva = await this.atualizar(guiaNova);

      this.alterarGuiaAtiva(guiaSalva.id);

      await this.carregar();

      return guiaSalva;
    } catch (err) {
      this.errorHandlerService.handleError(err);
      throw err;
    }
  }

  public async alterarGuiaAtiva(id: string): Promise<void> {
    try {
      this.guias.forEach((guia) => (guia.ativa = guia.id === id));

      await this.atualizarVarias();
      await this.carregar();
    } catch (err) {
      this.errorHandlerService.handleError(err);
      throw err;
    }
  }

  updateGuias() {
    this.guias$.next(this.guias);
  }

  getGuias(): Observable<Guia[]> {
    return this.guias$.asObservable();
  }

  getIndexGuiaAtiva(): Observable<number> {
    return this.indexGuiaAtiva$;
  }

  getIndexMouseOver(): Observable<number> {
    return this.indexMouseOver$;
  }

  updateGuiaAtiva() {
    this.guiaAtiva$.next(this.guiaAtiva);
  }

  getGuiaAtiva(): Observable<Guia> {
    return this.guiaAtiva$.asObservable();
  }

  public get valueGuiaAtiva(): Guia {
    return this.guiaAtiva$.getValue();
  }
}
