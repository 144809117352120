import { Component, OnInit, OnDestroy } from "@angular/core";
import { LeitorParameters } from "./leitor.parameters";
import { PainelLateralService } from "../timer/painel-lateral/painel-lateral.service";
import { Subscription } from "rxjs";
import { UsuarioPreferenciasService } from "../../services/data-services/usuario.preferencias.service";
import { ConfiguracoesUsuario } from "../../models/usuario/ConfiguracoesUsuario";
import { UiService } from "src/app/services/ui.service";
import { Tema, TipoFundo } from "src/app/models/tema";

@Component({
  selector: "app-leitor",
  templateUrl: "./leitor.component.html",
  styleUrls: ["./leitor.component.scss"],
})
export class LeitorComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  public params: LeitorParameters;
  public MostrarPainelLateral: boolean;
  public configuracoes: ConfiguracoesUsuario;

  public backgroundImage: string;
  public backgroundColor: string;
  public foreground: string;

  constructor(
    private painelLateralService: PainelLateralService,
    private usuarioPreferenciasService: UsuarioPreferenciasService,
    private uiService: UiService
  ) {
    this.params = new LeitorParameters();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.painelLateralService.$mostrarPainel.subscribe(
        (visivel) => (this.MostrarPainelLateral = visivel)
      )
    );
    this.subscriptions.push(
      this.usuarioPreferenciasService.$Configuracoes.subscribe(
        (c) => (this.configuracoes = c)
      )
    );
    this.subscriptions.push(
      this.uiService.getTema().subscribe((t) => this.tema_changed(t))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = [];
  }

  public painelLateral_dismiss(): void {
    this.painelLateralService.togglePainel();
  }

  private tema_changed(t: Tema): void {
    this.backgroundImage =
      t && t.tipoFundo === TipoFundo.Imagem ? t.background : "";
    this.backgroundColor =
      t && t.tipoFundo === TipoFundo.Cor ? t.background : "";
    this.foreground = t ? t.foreground : "";
  }
}
