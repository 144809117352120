<div class="btn-toolbar-placeholder">
  <div
    class="btn-toolbar-icon"
    [style.color]="foregroundBotao"
    [style.backgroundColor]="backgroundBotao"
  >
    <div class="icon icon-{{ icone }}"></div>
  </div>
  <div
    class="btn-toolbar-text"
    [style.color]="foregroundLegenda"
    [style.backgroundColor]="backgroundLegenda"
  >
    {{ texto }}
  </div>
</div>
