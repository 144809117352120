<button mat-icon-button (click)="openDialog()" class=".navbar-button-icon">
  <mat-icon
    svgIcon="bell"
    [matBadge]="(historicoAtualizacoes$ | async).length"
    [matBadgeHidden]="
      historicoAtualizacoes$ && (historicoAtualizacoes$ | async).length <= 0
    "
    matBadgeColor="warn"
    matBadgeSize="small"
    matBadgePosition="after"
  ></mat-icon>
</button>
