import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { UsuarioPreferenciasService } from "../data-services/usuario.preferencias.service";

export class TextStyle {
  types: string[] = [];
  fontsize: number;
  default: boolean;
}

export const TextStyles: TextStyle[] = [
  { types: [], fontsize: 10, default: true }, // converter px para pt
  {
    types: ["introducao-busca", "tituloprincipal"],
    fontsize: 17,
    default: false,
  },
  { types: ["parte"], fontsize: 14, default: false },
  { types: ["livro"], fontsize: 13, default: false },
  { types: ["ato"], fontsize: 13, default: false },
  { types: ["titulo"], fontsize: 11, default: false },
  { types: ["capitulo"], fontsize: 10, default: false },
  { types: ["preambulo"], fontsize: 11, default: false },
  { types: ["sumula"], fontsize: 12, default: false },
];

@Injectable()
export class TextFormatingService {
  podeAumentar: Observable<boolean>;
  private _podeAumentar = new BehaviorSubject<boolean>(false);

  podeDiminuir: Observable<boolean>;
  private _podeDiminuir = new BehaviorSubject<boolean>(false);

  fontIncrement: Observable<number>;
  private _fontIncrement = new BehaviorSubject<number>(0);

  constructor(private usuarioPreferenciasService: UsuarioPreferenciasService) {
    this.podeAumentar = this._podeAumentar.asObservable();
    this.podeDiminuir = this._podeDiminuir.asObservable();

    this.fontIncrement = this._fontIncrement.asObservable();

    usuarioPreferenciasService.$Configuracoes.subscribe((c) => {
      if (c) {
        this._fontIncrement.next(c.fontSize);
      } else {
        this._fontIncrement.next(0);
      }

      this._podeDiminuir.next(this._fontIncrement.value > -3);
      this._podeAumentar.next(this._fontIncrement.value < 8);
    });
  }

  private updateFontSize(fontsize: number) {
    this._fontIncrement.next(fontsize);

    const config = this.usuarioPreferenciasService.Configuracoes;
    config.fontSize = fontsize;

    this.usuarioPreferenciasService.alterarConfiguracoes(config);
  }

  increaseFontSize() {
    const fontsize = this._fontIncrement.value + 1;
    this.updateFontSize(fontsize);
  }

  decreaseFontSize() {
    const fontsize = this._fontIncrement.value - 1;
    this.updateFontSize(fontsize);
  }
}
