import { Injectable } from "@angular/core";
import { AppConfig } from "../app.config";
import { HttpClient } from "@angular/common/http";
import {
  CompressionHelper,
  CompressedResult,
} from "../helpers/compression.helper";
import { StatusService } from "./status.service";
import { UserdataDTO } from "../models/UserdataSync";
import { UsuarioGuiasService } from "./data-services/usuario.guias.service";
// azure card 358 Desativar botão caiu em prova
import { UsuarioProvasService } from "./data-services/usuario.provas.service";
import { UsuarioMarcacoesService } from "./data-services/usuario.marcacoes.service";
import { UsuarioEstatisticasService } from "./data-services/usuario.estatisticas.service";
import { UsuarioApontamentosService } from "./data-services/usuario.apontamentos.service";
import { UsuarioPreferenciasService } from "./data-services/usuario.preferencias.service";
import { SignalrService } from "./signalr.service";
import { LeiRepositorio } from "../repositorios/lei.repositorio";

@Injectable()
export class DadosService {
  constructor(
    private statusService: StatusService,
    private usuarioGuiasService: UsuarioGuiasService,
    // azure card 358 Desativar botão caiu em prova
    private usuarioProvasService: UsuarioProvasService,
    private usuarioMarcacoesService: UsuarioMarcacoesService,
    private usuarioEstatisticasService: UsuarioEstatisticasService,
    private usuarioApontamentosService: UsuarioApontamentosService,
    private usuarioPreferenciasService: UsuarioPreferenciasService,
    private leiRepositorio: LeiRepositorio,
    private signalrService: SignalrService,

    private httpClient: HttpClient
  ) {}

  public baixar(): Promise<void> {
    const taskName = "baixar-leis";
    const url = `${AppConfig.apiEndpoint}/sync`;

    const processarDadosBaixados = (json: string): Promise<void> => {
      this.statusService.mostrarMensagemProgresso(
        "Salvando informações",
        taskName
      );
      return new Promise(async (resolve) => {
        const userdata = <UserdataDTO>JSON.parse(json);

        // azure card 358 Desativar botão caiu em prova
        this.statusService.mostrarMensagemProgresso(
          "Processando base de provas",
          taskName
        );
        await this.usuarioProvasService.fromNuvem(userdata.provaDatasource);

        this.statusService.mostrarMensagemProgresso(
          "Processando guias",
          taskName
        );
        await this.usuarioGuiasService.fromNuvem(userdata.guias);
        this.usuarioGuiasService.carregar();

        this.statusService.mostrarMensagemProgresso(
          "Processando marcações de prova",
          taskName
        );
        await this.usuarioMarcacoesService.fromNuvem(userdata.marcacoesProva);

        this.statusService.mostrarMensagemProgresso(
          "Processando comentários",
          taskName
        );

        this.statusService.mostrarMensagemProgresso(
          "Processando referências",
          taskName
        );

        this.statusService.mostrarMensagemProgresso(
          "Processando marca-textos",
          taskName
        );

        this.statusService.mostrarMensagemProgresso(
          "Processando estatísticas de leitura",
          taskName
        );
        await this.usuarioEstatisticasService.salvarLote(
          userdata.estatisticasLeitura,
          false
        );

        this.statusService.mostrarMensagemProgresso(
          "Processando apontamentos",
          taskName
        );
        await this.usuarioApontamentosService.fromNuvem(userdata.apontamentos);

        this.statusService.mostrarMensagemProgresso(
          "Processando configurações",
          taskName
        );
        await this.usuarioPreferenciasService.fromNuvem(userdata);

        resolve();
      });
    };

    const atualizarComponentes = (): Promise<void> => {
      return new Promise((onsuccess, onerror) => {
        const tasks = new Array<Promise<void>>();

        tasks.push(this.usuarioGuiasService.carregar());

        Promise.all(tasks)
          .then(() => {
            onsuccess();
          })
          .catch((err) => {
            onerror(err);
          });
      });
    };

    const baixarTudo = (): Promise<void> => {
      return new Promise(async (resolve, reject) => {
        try {
          await this.leiRepositorio.clearRepository();
          await this.leiRepositorio.atualizarLista();

          const syncResult = <CompressedResult>(
            await this.httpClient.get(url).toPromise()
          );
          const data = CompressionHelper.unzip(syncResult);

          await processarDadosBaixados(data);
          this.statusService.mostrarMensagemProgresso(
            "Iniciando serviço de sincronização",
            taskName
          );

          await this.signalrService.conectar();

          this.statusService.DataUltimaAtualizacao = new Date();
          await atualizarComponentes();

          this.statusService.ocultarMensagemProgresso(taskName);
          resolve();
        } catch (err) {
          reject(err);
        }
      });
    };

    return baixarTudo();
  }
}
