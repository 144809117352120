import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy,
} from "@angular/core";
import { Guia } from "../../../models/Guia";
import { UiService } from "../../../services/ui.service";
import { combineLatest, Observable, of, Subscription } from "rxjs";
import { UsuarioGuiasService } from "../../../services/data-services/usuario.guias.service";
import { map } from "rxjs/operators";
import { UsuarioPreferenciasService } from "src/app/services/data-services/usuario.preferencias.service";

@Component({
  selector: "app-barra-navegacao",
  templateUrl: "./barra-navegacao.component.html",
  styleUrls: ["./barra-navegacao.component.scss"],
})
export class BarraNavegacaoComponent implements OnInit, OnDestroy {
  public foreground: string;

  private subscriptions: Subscription[] = [];

  public guias$: Observable<Guia[]> = of([]);

  public indexGuiaAtiva$: Observable<number> = of(0);

  public indexMouseOver$: Observable<number> = of(0);

  public larguraTela$: Observable<number> = of(0);
  larguraAba: number = 180;

  quantidadeguia$: Observable<number> = of(0);
  larguraAba$: Observable<string> = of("");
  larguraPapel$: Observable<number> = of(2);

  @Output()
  expandirPainelNovaGuia = new EventEmitter<boolean>();

  constructor(
    private usuarioGuiasService: UsuarioGuiasService,
    private uiService: UiService,
    private usuarioPreferenciasService: UsuarioPreferenciasService
  ) {}

  async ngOnInit() {
    this.larguraTela$ = this.uiService.getLarguraTela();
    this.guias$ = this.usuarioGuiasService.getGuias();

    this.indexGuiaAtiva$ = this.usuarioGuiasService.getIndexGuiaAtiva();

    this.indexMouseOver$ = this.usuarioGuiasService.getIndexMouseOver();
    this.quantidadeguia$ = this.guias$.pipe(map((guias) => guias.length));


    this.larguraAba$ = combineLatest([
      this.larguraTela$,
      this.quantidadeguia$,
      this.larguraPapel$,
    ]).pipe(
      map(([larguraTela, aba, larguraPapel]) => {
        return ((larguraTela / 3) * larguraPapel - 32) / aba < this.larguraAba
          ? `${((larguraTela / 3) * larguraPapel - 32) / aba}px`
          : `${this.larguraAba}px`;
      })
    );

    this.usuarioGuiasService.carregar();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = [];
  }

  public async alternarPara(guia: Guia) {
    await this.usuarioGuiasService.alterarGuiaAtiva(guia.id);
  }

  public fecharGuia(idGuia: string) {
    this.usuarioGuiasService.fecharGuia(idGuia);
  }
}
