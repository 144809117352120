import { Component, OnInit, OnDestroy } from '@angular/core';
import { PlaceholderPaineisService, PainelMarcacoesProvaParameters, PainelComentariosParameters } from './placeholder-paineis.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-placeholder-paineis',
  templateUrl: './placeholder-paineis.component.html',
  styleUrls: ['./placeholder-paineis.component.scss']
})
export class PlaceholderPaineisComponent implements OnInit, OnDestroy {
  private s_painelMarcacoesProvaParametersObservable: Subscription;
  private s_painelComentariosParametersObservable: Subscription;

  public painelMarcacoesProvaParameters: PainelMarcacoesProvaParameters;
  public painelComentariosParameters: PainelComentariosParameters;

  public constructor(
    private placeholderPaineisService: PlaceholderPaineisService
  ) { }

  public ngOnInit(): void {
    this.s_painelMarcacoesProvaParametersObservable = this.placeholderPaineisService.painelMarcacoesProvaParametersObservable.subscribe(params => { this.painelMarcacoesProvaParameters = params; });
    this.s_painelComentariosParametersObservable = this.placeholderPaineisService.painelComentariosParametersObservable.subscribe(params => { this.painelComentariosParameters = params; });
  }

  public ngOnDestroy(): void {
    this.s_painelComentariosParametersObservable.unsubscribe();
    this.s_painelMarcacoesProvaParametersObservable.unsubscribe();
  }

  public fecharPaineis(e: Event): void {
    if (
      ((<HTMLDivElement>e.target).parentNode && (<HTMLDivElement>e.target).parentNode.nodeName === 'APP-PLACEHOLDER-PAINEIS') ||
      ((<HTMLDivElement>e.target).parentElement && (<HTMLDivElement>e.target).parentElement.id === 'placeholder-paineis')) {
      this.placeholderPaineisService.fecharPainelMarcacoesProva();
      this.placeholderPaineisService.fecharPainelComentarios();
    }
  }

  get visible(): boolean {
    return this.placeholderPaineisService.visible;
  }
}
