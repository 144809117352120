import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  InfoLeiComponent,
  InfoLeiModel,
} from "src/app/components/leitor/nova-guia/info-lei/info-lei.component";
import { LeiLookup } from "src/app/models/lei/lei.lookup";
import {
  EstatisticasLeitura,
  Tempo,
} from "src/app/models/usuario/EstatisticasLeitura";
import { UsuarioApontamentosService } from "src/app/services/data-services/usuario.apontamentos.service";
import { UsuarioPreferenciasService } from "src/app/services/data-services/usuario.preferencias.service";
import { Apontamento } from "src/app/services/timer.service";

@Component({
  selector: "app-item-navegacao-pesquisa-leis",
  templateUrl: "./item-navegacao-pesquisa-leis.component.html",
  styleUrls: ["./item-navegacao-pesquisa-leis.component.scss"],
})
export class ItemNavegacaoPesquisaLeisComponent implements OnInit {
  item: LeiLookup;
  tempoRestante: Tempo;
  dataAlteracao: string;

  @Input() set setItem(setItem: LeiLookup) {
    this.item = setItem;
    this.tempoRestante = EstatisticasLeitura.CalcularTempoRestante(
      this.usuarioPreferenciasService.Configuracoes.palavrasMinuto,
      this.item.progresso,
      this.item.quantidadePalavras
    );
    this.dataAlteracao = this.item.dataHoraUltimaModificacaoOficial
      ? "Última alteração em: " +
        this.datePipe.transform(
          this.item.dataHoraUltimaModificacaoOficial,
          "dd/MM/yyyy"
        )
      : "Última alteração indefinida";
  }

  @Output() favorito_mudou: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private usuarioPreferenciasService: UsuarioPreferenciasService,
    private usuarioApontamentosService: UsuarioApontamentosService
  ) {}

  ngOnInit() {}

  public btnFavorito_click(evt: Event): void {
    evt.stopPropagation();
    this.favorito_mudou.emit(!this.item.favorita);
  }

  public btnInfo_click(evt: Event, item: LeiLookup) {
    evt.stopPropagation();

    const model = new InfoLeiModel();
    model.idLei = item.id;
    model.nomeLei = item.titulo;
    model.dataAtualizacao = this.dataAlteracao;
    model.qtdPalavras = item.quantidadePalavras;
    model.progressoLido = item.progresso;

    const config = this.usuarioPreferenciasService.Configuracoes;
    if (config) {
      model.tempoRestante = Tempo.toTimeString(
        EstatisticasLeitura.CalcularTempoRestante(
          config.palavrasMinuto,
          this.item.progresso,
          item.quantidadePalavras
        )
      );
      model.tempoTotal = Tempo.toTimeString(
        EstatisticasLeitura.CalcularTempoTotal(
          config.palavrasMinuto,
          item.quantidadePalavras
        )
      );
    }

    model.tempoTimer = "00:00:00";
    let tempoTotalSecAux = 0;
    this.usuarioApontamentosService.buscar(item.id).then((apontamentos) => {
      if (apontamentos) {
        apontamentos.forEach((ap) => {
          const a = Apontamento.fromJSON(JSON.stringify(ap));
          tempoTotalSecAux += a.tempoTotalSegundos;
        });
      }

      model.tempoTimer = Tempo.toTimeStringFromSeconds(tempoTotalSecAux);
    });

    this.dialog.open(InfoLeiComponent, {
      width: "420px",
      data: model,
    });
  }

  pad(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
}
