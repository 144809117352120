import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription, Observable, of } from "rxjs";
import { NovaGuiaService, ModosOrdenacao } from "./nova-guia.service";
import { MatDialog } from "@angular/material/dialog";
import { Guia } from "../../../models/Guia";
import { StatusService } from "../../../services/status.service";
import { ConfiguracoesUsuario } from "../../../models/usuario/ConfiguracoesUsuario";
import { LeiLookup } from "../../../models/lei/lei.lookup";
import { UsuarioEstatisticasService } from "../../../services/data-services/usuario.estatisticas.service";
import { UsuarioGuiasService } from "../../../services/data-services/usuario.guias.service";
import { ItemLookupOrdenacaoNovaGuia } from "../../../models/lei/item.lookup.ordenacao.nova.guia";
import { Conteudo } from "../../../models/pagina/conteudo";
import { BuscaService } from "../../../services/busca.service";
import { BuscaPanelParameters } from "../../leitor-content-panelbusca/busca-panel.parameters";
import { LeiRepositorio } from "../../../repositorios/lei.repositorio";
import { DateHelpers } from "../../../helpers/date.helpers";

@Component({
  selector: "app-nova-guia",
  templateUrl: "./nova-guia.component.html",
  styleUrls: ["./nova-guia.component.scss"],
})
export class NovaGuiaComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  public appOffline$: Observable<boolean> = of(false);

  linhasLoading: number;
  configuracoes: ConfiguracoesUsuario;
  guiaAtiva: Guia;
  opcoesModoOrdenacao = ModosOrdenacao;

  listaLeis: Observable<LeiLookup[]>;
  filtrarFavoritos: Observable<boolean>;
  modoOrdenacao: Observable<ItemLookupOrdenacaoNovaGuia>;

  carregando: boolean;

  txtBusca: string;

  constructor(
    private novaGuiaService: NovaGuiaService,
    private statusService: StatusService,
    private usuarioEstatisticasService: UsuarioEstatisticasService,
    private usuarioGuiasService: UsuarioGuiasService,
    private leiRepositorio: LeiRepositorio,
    private buscaService: BuscaService,
    public dialog: MatDialog
  ) {}

  public async ngOnInit(): Promise<void> {
    this.listaLeis = this.novaGuiaService.Leis;
    this.filtrarFavoritos = this.novaGuiaService.FiltrarFavoritos;
    this.modoOrdenacao = this.novaGuiaService.ModoOrdenacao;

    this.subscriptions.push(
      this.novaGuiaService.EventosLoading.subscribe((c) => {
        if (!c) {
          return;
        }
        this.carregando = c.length > 0;
      })
    );
    this.subscriptions.push(
      this.usuarioGuiasService
        .getGuiaAtiva()
        .subscribe((g) => (this.guiaAtiva = g))
    );
    this.subscriptions.push(
      this.novaGuiaService.TextoBusca.subscribe((txt) => (this.txtBusca = txt))
    );

    this.appOffline$ = this.statusService.getAppOffline();

    setTimeout(() => {
      const div = document.querySelector(".background-animation");
      if (div) {
        this.linhasLoading = Math.floor(div.clientHeight / 57);
      }
    }, 100);

    await this.checarAtualizacaoDeLeis();

    this.novaGuiaService.carregarLeis(false);
  }

  private async checarAtualizacaoDeLeis() {
    if (!this.statusService.isAppOnline) return;

    if (
      !this.statusService.DataUltimaAtualizacao ||
      DateHelpers.SegundosEntre(
        new Date(Date.now()),
        new Date(this.statusService.DataUltimaAtualizacao)
      ) > DateHelpers.totalSegundosEmUmDia
    ) {
      const atualizacaoTask = "atualizacaoTask";
      try {
        this.statusService.mostrarMensagemProgresso(
          "buscando atualizações de leis",
          atualizacaoTask
        );
        await this.leiRepositorio.clearRepository();
        await this.leiRepositorio.atualizarLista();
        this.statusService.DataUltimaAtualizacao = new Date(Date.now());
      } finally {
        this.statusService.ocultarMensagemProgresso(atualizacaoTask);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = [];

    const taskname = "carregar-menu";
    this.statusService.ocultarMensagemProgresso(taskname);
  }

  public alterarModoOrdenacao(novoModo: ItemLookupOrdenacaoNovaGuia): void {
    this.novaGuiaService.alterarModoOrdenacao(novoModo);
  }

  public abrirBusca(): void {
    this.buscaService.abrirPainelBusca(null);
    const params = new BuscaPanelParameters();
    params.textoBuscar = this.txtBusca;
    params.buscarTodosDocumentos = false;
  }

  public selecionar(item: LeiLookup): void {
    this.usuarioGuiasService.alterarConteudoGuia(this.guiaAtiva.id, item);
  }

  public async favoritoAlterardo(item: LeiLookup) {
    const estatisticas = await this.usuarioEstatisticasService.marcarFavorito(
      item.id
    );
    item.favorita = estatisticas.favorito;
  }
}
