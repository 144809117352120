import { Component, OnInit, OnDestroy } from "@angular/core";
import { DataSource } from "@angular/cdk/table";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { PaineisService } from "../leitor-content-tabcontent/paineis.service";
import { RevisaoAnotacao } from "./novidades.service";
import { StatusService } from "../../services/status.service";
import { DadosService } from "../../services/dados.service";

@Component({
  selector: "app-novidades",
  templateUrl: "./novidades.component.html",
  styleUrls: ["./novidades.component.scss"],
})
export class NovidadesComponent implements OnInit, OnDestroy {
  public exibirModalNovidades: Boolean;
  public atualizacaoDisponivel: Boolean;

  private update_unsub: Subscription;
  private paineis_unsub: Subscription;

  constructor(private paineisService: PaineisService) {}

  ngOnInit() {
    // TODO
    // this.novidadesService.itensRevisaoAnotacao.subscribe((itensRevisao) => {
    //     if (!itensRevisao) {
    //         return;
    //     }

    //     this.revisaoAnotacaoDataSource = new RevisaoAnotacaoDataSource();
    //     this.revisaoAnotacaoDataSource.revisoesAnotacao.next(itensRevisao);
    // });
    // this.guiasService.guiasObservable.subscribe((guias) => {
    //     this.guias = guias;
    // });
    // this.conteudoService.paginaAtiva.subscribe(p => {
    //     this.pagina = p;
    // });

    this.paineis_unsub = this.paineisService.painelNovidades.subscribe(
      (exibirModal) => {
        this.exibirModalNovidades = exibirModal;
      }
    );
  }

  ngOnDestroy() {
    this.update_unsub.unsubscribe();
    this.paineis_unsub.unsubscribe();
  }

  fechar() {
    this.paineisService.fecharPainelNovidades();
  }

  itemRevisao_Click() {
    // TODO
    // this.idItemRevisao = element.idItem;
    // this.guias.forEach(g => {
    //     g.ativa = false;
    // });
    // const novaGuia = new Guia();
    // novaGuia.idConjunto = element.idConjunto;
    // novaGuia.idLei = element.idLei;
    // novaGuia.titulo = element.tituloGuia;
    // novaGuia.ativa = true;
    // novaGuia.idUltimoItemFoco = this.idItemRevisao;
    // this.guias.push(novaGuia);
    // this.guiasService.atualizar(this.guias, true);
    // this.conteudoService.itemRevisaoAnotacaoSubject.next(element);
    // this.paineisService.fecharPainelNovidades();
    // this.novidadesService.removerItemRevisao(element.idItem);
  }

  async update() {
    location.reload();
  }
}

export class RevisaoAnotacaoDataSource implements DataSource<RevisaoAnotacao> {
  revisoesAnotacao = new BehaviorSubject<RevisaoAnotacao[]>(null);

  connect(): Observable<RevisaoAnotacao[]> {
    return this.revisoesAnotacao.asObservable();
  }
  disconnect(): void {
    this.revisoesAnotacao.complete();
  }
}
