import { LeiStorage } from "./lei.storage";


export class LeiInfo {
  id: string;
  descricao: string;
  quantidadeItens: number;
  quantidadePalavras: number;
  dataHoraUltimaModificacaoTextoLei: Date;
  dataHoraUltimaModificacaoOficial: Date;
  tipoDocumento: number;
  disponivel: boolean;
  atualizacaoPendente: boolean;

  static fromStorage(lei: LeiStorage): LeiInfo {
    return {
      id: lei.id,
      descricao: lei.descricao,
      quantidadeItens: lei.quantidadeItens,
      quantidadePalavras: lei.quantidadePalavras,
      dataHoraUltimaModificacaoTextoLei: lei.dataHoraUltimaModificacaoTextoLei,
      dataHoraUltimaModificacaoOficial: lei.dataHoraUltimaModificacaoOficial,
      tipoDocumento: lei.tipoDocumento,
      disponivel: lei.dados ? true : false,
      atualizacaoPendente: lei.atualizacaoPendente,
    };
  }


}
