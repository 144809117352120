import { Injectable } from "@angular/core";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  IHttpConnectionOptions,
} from "@aspnet/signalr";
import { AppConfig } from "../app.config";
import { BehaviorSubject, Observable } from "rxjs";
import { StatusService } from "./status.service";
import { EntitiesHelper } from "../helpers/entities.helper";
import { CompressionHelper } from "../helpers/compression.helper";
import { AuthService } from "../modules/shared/services/auth.service";

@Injectable()
export class SignalrService {
  private hubConnection: HubConnection;
  private actionUrl: string;

  public $Mensagem: Observable<OperacaoSignalR>;
  private _Mensagem = new BehaviorSubject<OperacaoSignalR>(null);

  PendingChanges: Observable<number>;
  private pendingChanges = new BehaviorSubject<number>(
    this.countPendingChanges()
  );

  private firstConnectionEstabilished = false;

  constructor(private statusService: StatusService, private auth: AuthService) {
    this.actionUrl = `${AppConfig.apiEndpoint}/hub`;

    this.$Mensagem = this._Mensagem.asObservable();
    this.PendingChanges = this.pendingChanges.asObservable();

    this.initializeService();

    statusService.getAppOffline().subscribe(async (offline) => {
      if (this.firstConnectionEstabilished && !offline) {
        await this.conectar();
        this.processarFila();
      } else if (this.firstConnectionEstabilished) await this.desconectar();
    });
  }

  private initializeService() {
    const options: IHttpConnectionOptions = {};

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.actionUrl, options)
      .build();

    this.hubConnection.onclose(async () => await this.conectar());

    this.hubConnection.on("Sync", (message) => this.mensagemRecebida(message));
    this.hubConnection.on("success", (data) => this.sucessoSincronizacao(data));
    this.hubConnection.on("error", (data) => console.error(data));

    this.conectar();
  }

  private get ready(): boolean {
    return (
      this.statusService.isAppOnline &&
      this.hubConnection &&
      this.hubConnection.state == HubConnectionState.Connected
    );
  }

  private get fila(): OperacaoSignalR[] {
    let retorno: OperacaoSignalR[] = [];
    const json = localStorage.getItem("fila-envio");

    if (json && JSON.parse(json)) retorno = JSON.parse(json);

    return retorno;
  }

  private set fila(value: OperacaoSignalR[]) {
    this.pendingChanges.next(value ? value.length : 0);
    localStorage.setItem("fila-envio", value ? JSON.stringify(value) : null);
  }

  async conectar(): Promise<void> {
    const userId = this.auth.userID;

    if (
      this.statusService.isAppOnline &&
      this.hubConnection &&
      this.hubConnection.state !== HubConnectionState.Connected
    ) {
      await this.hubConnection.start();
      await this.hubConnection.invoke("JoinGroup", userId);
      await this.processarFila();

      this.firstConnectionEstabilished = true;
    }
  }

  private async desconectar() {
    if (
      this.hubConnection &&
      this.hubConnection.state === this.hubConnection.state
    )
      await this.hubConnection.stop();
  }

  public async enviarMensagem(
    mensagem: OperacaoSignalR,
    tipoObjeto: EnumTipoObjeto
  ): Promise<void> {
    mensagem.userId = this.auth.userID;

    if (!mensagem.tipoObjeto) mensagem.tipoObjeto = tipoObjeto;

    this.incluirMensagemFila(mensagem);

    if (this.ready)
      await this.hubConnection.send("Sync", JSON.stringify(mensagem));
  }

  public async enviarMensagens(
    mensagens: OperacaoSignalR[],
    tipoObjeto: EnumTipoObjeto
  ): Promise<void> {
    mensagens.forEach(async (mensagem) => {
      await this.enviarMensagem(mensagem, tipoObjeto);
    });
  }

  private async processarFila(): Promise<void> {
    const fila = EntitiesHelper.Copy(this.fila);

    if (!fila || fila.length === 0) return;

    await this.enviarMensagens(fila, null);
  }

  private mensagemRecebida(mensagem: string): void {
    const json = CompressionHelper.strUnzip(mensagem);
    const msgObj = <OperacaoSignalR>JSON.parse(JSON.parse(json));
    this._Mensagem.next(msgObj);
  }

  private sucessoSincronizacao(data: string): void {
    const json = CompressionHelper.strUnzip(data);
    const mensagemGravada: OperacaoSignalR = JSON.parse(json);

    let fila = this.fila;
    if (fila)
      fila = fila.filter((i) => i.operationId === mensagemGravada.operationId);

    this.fila = fila;
  }

  private incluirMensagemFila(mensagem: OperacaoSignalR) {
    const fila = this.fila;

    if (fila.findIndex((i) => i.operationId === mensagem.operationId) === -1)
      fila.push(mensagem);

    this.fila = fila;
  }

  private countPendingChanges(): number {
    const fila = this.fila;
    return fila.length;
  }
}

export class OperacaoSignalR {
  public operationId: string;
  public userId: string;
  public tipoObjeto: EnumTipoObjeto;
  public dados: any;

  constructor() {
    this.operationId = EntitiesHelper.generateGuid();
  }
}

export enum EnumTipoObjeto {
  Instituicao = 0,
  Banca = 1,
  Ano = 2,
  Tipo = 3,
  Cargo = 4,
  Guias = 5,
  Marcacoes = 6,
  Comentarios = 7,
  Grifos = 8,
  Estatisticas = 9,
  Apontamentos = 10,
  Preferencias = 11,
  Referencias = 12,
}
