<div class="container">
  <app-leitor-content-panelbusca
    *ngIf="mostrarPainelBusca"
  ></app-leitor-content-panelbusca>
  <app-leitor-toolbar></app-leitor-toolbar>

  <div class="app-leitor-content-container">
    <app-barra-navegacao
      class="app-leitor-content-barra-navegacao"
    ></app-barra-navegacao>

    <ng-container *ngIf="mostrarResultadoBuscaAvancada; else elseBuscaAvancada">
      <app-leitor-content-panelbuscaavancada
        class="app-leitor-content-busca-avancada"
      ></app-leitor-content-panelbuscaavancada>
    </ng-container>

    <ng-template #elseBuscaAvancada>
      <ng-container *ngIf="mostrarNovaGuia; else elseNovaGuia">
        <app-nova-guia class="app-leitor-content-nova-guia"></app-nova-guia>
      </ng-container>

      <ng-template #elseNovaGuia>
        <app-pagina class="app-leitor-content-pagina"></app-pagina>
      </ng-template>
    </ng-template>
  </div>

  <app-leitor-content-panelspinner></app-leitor-content-panelspinner>
  <app-splash></app-splash>
</div>
