
export class IdDataLei {
    ids: Array<string>;
    MaiorDataAlteracaoApp: Date;

    constructor() {
        this.ids = new Array<string>();
        this.MaiorDataAlteracaoApp = new Date(1,1,1);
        this.MaiorDataAlteracaoApp.setFullYear(1);
    }
}
