import { Injectable } from "@angular/core";
import {
  TextoPagina,
  TextoPaginaFunctions,
} from "../models/pagina/TextoPagina";
import { TextoArtigo } from "../constants/texto.artigo";
import { ConteudoService } from "./conteudo.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { StatusService } from "./status.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class QuizService {
  constructor(
    private conteudoService: ConteudoService,
    private httpClient: HttpClient,
    private statusService: StatusService,
    private matSnackBar: MatSnackBar
  ) {}

  private headers = new HttpHeaders({
    "content-type": "application/json",
  });

  public async getQuizQuestions(artTexto) {
    const taskname = "quiz-succeded";
    const response = new QuizQuestions();
    this.statusService.mostrarMensagemProgresso("Gerando quiz...", taskname);
    await this.httpClient
      .post(
        AppConfig.apiEndpoint + "/ChatGPT/ChatGPTQuiz",
        { texto: artTexto },
        { headers: this.headers }
      )
      .toPromise()
      .then((result: any) => {
        Object.assign(response, result);
      })
      .catch((err) => {
        Object.assign(response, undefined);
        this.matSnackBar.open("Erro ao gerar o quiz", null, { duration: 5000 });
      })
      .finally(() => {
        this.statusService.ocultarMensagemProgresso(taskname);
      });

    return response;
  }

  public getArtRange(id: string) {
    let inicio: boolean = false;
    const conteudo = this.conteudoService.getConteudo();
    const artIds = [];
    let artText = "";
    let artigo: string = "";

    let indexArtigo = conteudo.linhas.findIndex((x) => x.id == id);
    let textoAposArtigo: TextoPagina[] = conteudo.linhas.slice(indexArtigo);
    for (const texto of textoAposArtigo) {
      let tex: string = TextoPaginaFunctions.getVersao(texto).texto;
      let art: boolean = texto.tipoTexto === TextoArtigo;
      if (id === texto.id) {
        inicio = true;
        artIds.push(texto.id);
        artigo = texto.trechosPrefixo[0].texto;
        artText = artText + `${artigo} ${tex}`;
      } else if (!art && inicio) {
        artIds.push(texto.id);
        let prefix = "";
        if (
          texto.hasOwnProperty("trechosPrefixo") &&
          texto.trechosPrefixo.length > 0
        )
          prefix = texto.trechosPrefixo[0].texto;
        artText = artText + ` ${prefix} ${tex}`;
      } else if (art && inicio) {
        break;
      }
    }
    return { artIds: artIds, artText: artText, artigo: artigo };
  }
}

export class QuizQuestions {
  artigo?: string;
  question: string;
  anslistobj: string[];
  answer: string;
}
