import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { PopupsService } from "./components/popups/popups.service";
import {
  trigger,
  transition,
  style,
  animate,
  state,
} from "@angular/animations";
import { PopupsComponent } from "./components/popups/popups.component";
import { observable, Observable, of, Subscription } from "rxjs";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatIconRegistry } from "@angular/material/icon";
import { AppConfig } from "./app.config";
import { UsuarioPreferenciasService } from "./services/data-services/usuario.preferencias.service";
import { DialogoService } from "./components/popups/dialogo/dialogo.service";
import { DialogoComponent } from "./components/popups/dialogo/dialogo.component";
import { StorageHelper } from "./helpers/storage.helper";
import { StatusService } from "./services/status.service";
import { PopupCarregamentoComponent } from "./components/popups/popup-carregamento/popup-carregamento.component";
import { SignalrService } from "./services/signalr.service";
import { Icons, Icon } from "./models/ui/icon";
import { CheckForUpdateService } from "./services/checkForUpdateService";
import { DomSanitizer } from "@angular/platform-browser";
import { AuthService } from "./modules/shared/services/auth.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [
    trigger("transitionPopup", [
      state("true", style({ opacity: "1" })),
      state("false", style({ opacity: "0" })),
      transition("* => *", animate("300ms ease")),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  private s_available: Subscription;
  private s_user: Subscription;
  private s_visible: Subscription;
  private s_message: Subscription;
  private s_mensagensProgresso: Subscription;
  private s_model: Subscription;
  private s_usuarioPreferencias: Subscription;

  @ViewChild(PopupsComponent) popupsComponent: PopupsComponent;
  showPopup: boolean;

  dialogRefDialogo: MatDialogRef<DialogoComponent>;

  isLoggedIn: boolean;
  wasLoggedIn: boolean;
  carregarAplicativo: boolean;
  popupSincronizacaoAberta: boolean;

  private indicadorCarregamento: MatDialogRef<PopupCarregamentoComponent>;

  private workingMessage: string;

  constructor(
    private auth: AuthService,
    private popupsService: PopupsService,
    private usuarioPreferenciasService: UsuarioPreferenciasService,
    private dialogoService: DialogoService,
    private statusService: StatusService,
    private signalrService: SignalrService,
    private snackBar: MatSnackBar,
    private checkForUpdateService: CheckForUpdateService,
    public dialog: MatDialog,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    Icons.forEach((icon) =>
      Icon.Register(icon.description, iconRegistry, sanitizer)
    );
  }

  async ngOnInit() {
    this.checkForUpdateService.initialize();
    StorageHelper.connectDatabases();

    if (this.auth.userIsLoggedIn()) {
      if (!this.usuarioPreferenciasService.Configuracoes) {
        localStorage.clear();
        await StorageHelper.deletarDBs();
        window.location.replace("/logoff?clear=true");
      }
    }

    this.s_usuarioPreferencias =
      this.usuarioPreferenciasService.$Configuracoes.subscribe((config) => {
        this.carregarAplicativo = config ? true : false;
      });

    this.s_user = this.auth.$Token.subscribe(() => {
      this.wasLoggedIn = this.isLoggedIn;
      this.isLoggedIn = this.auth.userIsLoggedIn();
      let refreshed = false;

      if (typeof this.wasLoggedIn === "undefined") {
        this.wasLoggedIn = this.isLoggedIn;
        refreshed = true;
      }

      if (this.isLoggedIn && this.wasLoggedIn) {
        if (refreshed) {
          this.signalrService.conectar();
        }
      } else if (this.isLoggedIn && !this.wasLoggedIn) {
        // this.signalrService.conectar();
      } else if (this.wasLoggedIn && !this.isLoggedIn) {
      }
    });
    this.s_visible = this.popupsService.visible.subscribe((visible) => {
      this.showPopup = this.auth.userIsLoggedIn && visible;
    });

    this.s_model = this.dialogoService.$model.subscribe((m) => {
      const abrir = () => {
        this.dialogRefDialogo = this.dialog.open(DialogoComponent, {
          width: AppConfig.larguraIndicadorPopup,
          data: m,
          disableClose: true,
        });
      };

      const fechar = () => {
        if (this.dialogRefDialogo) this.dialogRefDialogo.close();
      };

      if (m) abrir();
      else fechar();
    });

    this.s_mensagensProgresso = this.statusService
      .getMensagensProgresso()
      .subscribe((pilhaMensagens) => {
        let msg: string = null;

        if (pilhaMensagens && pilhaMensagens.length > 0)
          msg = pilhaMensagens[pilhaMensagens.length - 1]
            ? pilhaMensagens[pilhaMensagens.length - 1].message
            : null;

        setTimeout(() => {
          if (msg === this.workingMessage || (!msg && !this.workingMessage))
            return;

          if (!this.workingMessage && msg) {
            this.indicadorCarregamento = this.dialog.open(
              PopupCarregamentoComponent,
              {
                disableClose: true,
              }
            );
          } else if (!msg && this.workingMessage) {
            this.indicadorCarregamento.close();
            this.indicadorCarregamento = undefined;
          }

          this.workingMessage = msg;
        });
      });

    this.s_message = this.statusService.getMessage().subscribe((err) => {
      if (!err) return;

      this.snackBar.open(err, "OK", {
        duration: 5000,
      });
    });
  }

  ngOnDestroy(): void {
    this.s_available.unsubscribe();
    this.s_user.unsubscribe();
    this.s_visible.unsubscribe();
    this.s_message.unsubscribe();
    this.s_mensagensProgresso.unsubscribe();
    this.s_model.unsubscribe();
    this.s_usuarioPreferencias.unsubscribe();
  }

  public tryClosePopup(evt: any): void {
    this.popupsComponent.tryClose(evt);
  }
}
