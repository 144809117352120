import { Injectable } from "@angular/core";
import { DataControleRepositorio } from "../repositorios/dataControle.repositorio";
import { StatusService } from "./status.service";
import { EnumTipoDataControle } from "../models/dataControle";

@Injectable()
export class LeiService {
  constructor(
    private dataControleRepositorio: DataControleRepositorio,
    private statusService: StatusService
  ) {}

  async verificarAtualizacoesModoOffline(): Promise<boolean> {
    if (!this.statusService.isAppOnline) {
      return false;
    } else {
      const dataControle = await this.dataControleRepositorio.buscar(
        EnumTipoDataControle.DataVerificacaoModoOffline
      );
      if (
        dataControle &&
        dataControle.data &&
        dataControle.data.toDateString() === new Date().toDateString()
      ) {
        return false;
      }

      return true;
    }
  }
}
