import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import {
  BuscaPanelParameters,
  estadoJanelaBusca,
} from "./busca-panel.parameters";
import { BuscaService } from "../../services/busca.service";
import { ProvaDados, FuncoesProva } from "../../models/Marcacao";
import { TipoSelecao } from "../../services/selection.service";
import { OpcaoToolbar } from "../leitor-toolbar/opcaoToolbar";
import { MatDialog } from "@angular/material/dialog";
import { Observable, of, Subscription } from "rxjs";
import {
  ErroPopupDataModel,
  ErroPopUpComponent,
} from "../popups/mensagem/erro-popup.component";
import { UsuarioGuiasService } from "../../services/data-services/usuario.guias.service";
import { UsuarioMarcacoesService } from "../../services/data-services/usuario.marcacoes.service";
import { UsuarioPreferenciasService } from "../../services/data-services/usuario.preferencias.service";
import { ConfiguracoesUsuario } from "../../models/usuario/ConfiguracoesUsuario";
import { Guia } from "../../models/Guia";
import { ConteudoService } from "../../services/conteudo.service";
import { Conteudo } from "../../models/pagina/conteudo";
import { BuscaSalva } from "../../models/UserData";
import { StatusService } from "../../services/status.service";

@Component({
  selector: "app-leitor-content-panelbusca",
  templateUrl: "./leitor-content-panelbusca.component.html",
  styleUrls: [
    "../leitor-content-tabcontent/paineis.styles.scss",
    "../leitor-content-pagina/formatacao-textos.scss",
    "./leitor-content-panelbusca.component.scss",
  ],
})
export class LeitorContentPanelBuscaComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  public params: BuscaPanelParameters;

  // Declaração do Enum usado no html
  estadoJanelaBusca = estadoJanelaBusca;
  opcoesMarcacao: OpcaoToolbar[];
  config: ConfiguracoesUsuario;
  indexMatchBuscaAtual: number;
  totalMatchsBuscaAtual: number;
  existeDocumentoAberto: boolean;

  private subscriptions: Subscription[] = [];

  public appOffline$: Observable<boolean> = of(false);

  public busca$: Observable<BuscaPanelParameters> = of(null);

  @ViewChild("textoBuscarInput") textoBuscarInput: ElementRef;
  @ViewChild("textoSalvarBuscaInput") textoSalvarBuscaInput: ElementRef;
  private guiaAtiva: Guia;

  constructor(
    private buscaService: BuscaService,
    private usuarioGuiasService: UsuarioGuiasService,
    private dialog: MatDialog,
    private usuarioMarcacoesService: UsuarioMarcacoesService,
    private usuarioPreferenciasService: UsuarioPreferenciasService,
    private conteudoService: ConteudoService,
    private statusService: StatusService
  ) {}

  public ngOnInit() {
    this.busca$ = this.buscaService.getBusca();

    this.subscriptions.push(
      this.buscaService.getBusca().subscribe((c) => this.conteudo_changed(c))
    );
    this.subscriptions.push(
      this.usuarioGuiasService
        .getGuiaAtiva()
        .subscribe((g) => this.guiaAtivaSubscribe(g))
    );

    this.appOffline$ = this.statusService.getAppOffline();

    if (!this.statusService.isAppOnline)
      this.params.buscarTodosDocumentos = false;

    this.statusService.testConnection();
  }

  guiaAtivaSubscribe(g: Guia) {
    if (this.guiaAtiva && this.params) {
      this.buscaService.salvarBusca(
        new BuscaSalva(this.params),
        this.guiaAtiva.id
      );
    }

    this.guiaAtiva = g;
  }

  private async conteudo_changed(params: BuscaPanelParameters) {
    if (!params) return;
    const newParams = params;

    const carregarOpcoesBusca = async () => {
      this.params = newParams;
      this.config = this.usuarioPreferenciasService.Configuracoes;
      const config = this.config;

      if (config) {
        if (this.params && this.params.marcacoesCaneta.length === 0) {
          this.params.marcacoesCaneta = OpcaoToolbar.CarregarItensMenu(
            config.parametrosCaneta
          ).filter(
            (marcacao) =>
              marcacao.tipo === TipoSelecao.Caneta1 ||
              marcacao.tipo === TipoSelecao.Caneta2 ||
              marcacao.tipo === TipoSelecao.Caneta3 ||
              marcacao.tipo === TipoSelecao.Caneta4 ||
              marcacao.tipo === TipoSelecao.Caneta5
          );
        }

        if (this.params && !this.params.provascaiuEmProva.ativo) {
          this.params.provascaiuEmProva = OpcaoToolbar.CarregarItensMenu(
            config.parametrosCaneta
          ).find((marcacao) => marcacao.tipo === TipoSelecao.Prova);
        }

        if (this.params && !this.params.comentariosComentado.ativo) {
          this.params.comentariosComentado = OpcaoToolbar.CarregarItensMenu(
            config.parametrosCaneta
          ).find((marcacao) => marcacao.tipo === TipoSelecao.Comentario);
        }

        if (this.params && !this.params.mnemonicosOpcao.ativo) {
          this.params.mnemonicosOpcao = OpcaoToolbar.CarregarItensMenu(
            config.parametrosCaneta
          ).find((marcacao) => marcacao.tipo === TipoSelecao.Mnemonico);
        }
      }

      await this.carregarProvas();

      if (!this.guiaAtiva || !this.guiaAtiva.idLei) {
        this.existeDocumentoAberto = false;
        if (this.params) {
          this.params.buscarTodosDocumentos = true;
        }
      } else {
        this.existeDocumentoAberto = true;
      }
    };
    await carregarOpcoesBusca();

    if (
      newParams &&
      !newParams.buscarTodosDocumentos &&
      newParams.matchsResultadoBusca &&
      newParams.matchsResultadoBusca
    ) {
      this.totalMatchsBuscaAtual = newParams.matchsResultadoBusca.length;
      this.indexMatchBuscaAtual =
        newParams.matchsResultadoBusca.findIndex(
          (n) => n.id === this.params.matchResultadoBuscaFoco.id
        ) + 1;
    }
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = [];
  }

  public ngAfterViewInit(): void {
    if (this.textoBuscarInput) {
      const el = <HTMLInputElement>this.textoBuscarInput.nativeElement;
      setTimeout(() => {
        el.focus();
      });
    }
  }

  toggleMarcacao(parametro: OpcaoToolbar) {
    if (
      parametro.tipo === TipoSelecao.Comentario &&
      this.params.comentarioBuscar.length > 0
    ) {
      return;
    } else if (
      parametro.tipo === TipoSelecao.Prova &&
      this.params.provaBuscar
    ) {
      return;
    }
    parametro.ativo = !parametro.ativo;
  }

  menuBusca_click(menu: string) {
    this.params.janelaFiltroAtiva = menu;
  }

  async fechar(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    await this.buscaService.fecharPainelBusca(true);
  }

  //#region Provas

  prova_changed(e, prova: ProvaDados) {
    this.params.provaBuscar = prova;
    this.params.provascaiuEmProva.ativo = true;
  }

  gerarLabelProva(prova: ProvaDados) {
    return FuncoesProva.getLabelProva(prova);
  }

  async carregarProvas() {
    const marcacoes = await this.usuarioMarcacoesService.listar();

    marcacoes.forEach((prova) => {
      if (
        !this.params.provasDados.find(
          (provaDados) =>
            ((!prova.dados.instituicao && !provaDados.instituicao) ||
              (prova.dados.instituicao &&
                provaDados.instituicao &&
                prova.dados.instituicao.id === provaDados.instituicao.id)) &&
            ((!prova.dados.banca && !provaDados.banca) ||
              (prova.dados.banca &&
                provaDados.banca &&
                prova.dados.banca.id === provaDados.banca.id)) &&
            ((!prova.dados.ano && !provaDados.ano) ||
              (prova.dados.ano &&
                provaDados.ano &&
                prova.dados.ano.id === provaDados.ano.id)) &&
            ((!prova.dados.tipo && !provaDados.tipo) ||
              (prova.dados.tipo &&
                provaDados.tipo &&
                prova.dados.tipo.id === provaDados.tipo.id)) &&
            ((!prova.dados.cargo && !provaDados.cargo) ||
              (prova.dados.cargo &&
                provaDados.cargo &&
                prova.dados.cargo.id === provaDados.cargo.id))
        )
      ) {
        this.params.provasDados.push(prova.dados);
      }
    });
  }

  //#endregion

  //#endregion

  //#endregion

  btnBuscar_click() {
    if (this.params.comentariosComentado.ativo)
      this.params.comentarioBuscar = this.params.textoBuscar;
    if (this.params.mnemonicosOpcao.ativo)
      this.params.mnemonicoBuscar = this.params.textoBuscar;
    if (
      this.params.textoBuscar === "" &&
      !BuscaPanelParameters.possuiCriteriosMarcacao(this.params)
    ) {
      this.params.buscaSemResultado = true;

      const data = new ErroPopupDataModel();
      data.titulo = "A busca não encontrou nenhum resultado";
      data.mensagem = "Altere as opções de busca e tente novamente";

      const dialogRef = this.dialog.open(ErroPopUpComponent, {
        width: "600px",
        data: data,
      });

      return;
    } else if (
      this.params.buscarTodosDocumentos &&
      this.params.textoBuscar.length > 0 &&
      this.params.textoBuscar.length < 4
    ) {
      this.params.buscaSemResultado = true;

      const data = new ErroPopupDataModel();
      data.titulo = "A busca não encontrou nenhum resultado";
      data.mensagem =
        "O termo à buscar é muito curto para buscar em todos os documentos. Altere as opções de busca e tente novamente";

      const dialogRef = this.dialog.open(ErroPopUpComponent, {
        width: "600px",
        data: data,
      });

      return;
    }

    this.params.idPagina = null;
    this.buscaService.buscar(this.params).then((p) => {
      if (p.buscaSemResultado) {
        const data = new ErroPopupDataModel();
        data.titulo = "A busca não encontrou nenhum resultado";
        data.mensagem = "Altere as opções de busca e tente novamente";

        const dialogRef = this.dialog.open(ErroPopUpComponent, {
          width: "600px",
          data: data,
        });
      }
    });
  }

  resultadoAnterior(e: Event) {
    e.stopPropagation();

    let index = this.indexMatchBuscaAtual - 1;
    if (index === 0) {
      index = this.params.matchsResultadoBusca.length - 1;
    } else {
      index--;
    }
  }

  proximoResultado(e: Event) {
    e.stopPropagation();

    let index = this.indexMatchBuscaAtual - 1;
    if (index === this.params.matchsResultadoBusca.length - 1) {
      index = 0;
    } else {
      index++;
    }
  }

  public toggle(opcao: OpcaoToolbar): void {
    if (
      opcao.tipo === TipoSelecao.Caneta1 ||
      opcao.tipo === TipoSelecao.Caneta2 ||
      opcao.tipo === TipoSelecao.Caneta3 ||
      opcao.tipo === TipoSelecao.Caneta4 ||
      opcao.tipo === TipoSelecao.Caneta5
    ) {
      const index = this.params.marcacoesCaneta.indexOf(opcao);
      this.params.marcacoesCaneta[index].ativo =
        !this.params.marcacoesCaneta[index].ativo;
    }
  }

  public mouseEnter(opcao: OpcaoToolbar): void {
    if (
      opcao.tipo === TipoSelecao.Caneta1 ||
      opcao.tipo === TipoSelecao.Caneta2 ||
      opcao.tipo === TipoSelecao.Caneta3 ||
      opcao.tipo === TipoSelecao.Caneta4 ||
      opcao.tipo === TipoSelecao.Caneta5
    ) {
      const index = this.params.marcacoesCaneta.indexOf(opcao);
      this.params.marcacoesCaneta[index].hover = true;
    }
  }

  public mouseLeave(opcao: OpcaoToolbar): void {
    if (
      opcao.tipo === TipoSelecao.Caneta1 ||
      opcao.tipo === TipoSelecao.Caneta2 ||
      opcao.tipo === TipoSelecao.Caneta3 ||
      opcao.tipo === TipoSelecao.Caneta4 ||
      opcao.tipo === TipoSelecao.Caneta5
    ) {
      const index = this.params.marcacoesCaneta.indexOf(opcao);
      this.params.marcacoesCaneta[index].hover = false;
    }
  }
}
