<div class="nova-guia-placeholder container flex-column">
  <div class="nova-guia-body shadow flex-1 relative hide-overflow flex-column">
    <ng-container
      *ngIf="
        !carregando && (listaLeis | async) && (listaLeis | async).length === 0;
        else elseTemplate
      "
    >
      <div
        class="msg-nenhum-resultado flex-column flex-1 foreground-primary center-center"
      >
        <mat-icon>search</mat-icon>
        <h2>Nenhum resultado encontrado</h2>
        <p *ngIf="!(appOffline$ | async)">
          <a (click)="abrirBusca()">Clique aqui</a> para pesquisar dentro do
          conteúdo de toda literatura do Super Vade Mecum
        </p>
      </div>
    </ng-container>
    <ng-template #elseTemplate>
      <virtual-scroller
        *ngIf="
          !carregando && (listaLeis | async) && (listaLeis | async).length > 0
        "
        #scroll
        [items]="listaLeis | async"
        (vsUpdate)="scroll.viewPortItems = $event"
      >
        <app-item-menu-nova-guia
          *ngFor="let item of scroll.viewPortItems"
          [item]="item"
          (favorito_mudou)="favoritoAlterardo(item)"
          (click)="selecionar(item)"
        ></app-item-menu-nova-guia>
      </virtual-scroller>
      <virtual-scroller
        *ngIf="carregando"
        #scroll
        [items]="listaLeis | async"
        (vsUpdate)="scroll.viewPortItems = $event"
      >
        <div class="background-animation">
          <div
            class="background-masker"
            style="margin-top: 2px !important"
          ></div>
          <div class="backgound-divisor"></div>
          <div
            *ngFor="let item of [].constructor(linhasLoading); let i = index"
          >
            <div class="background-masker"></div>
            <div class="backgound-divisor"></div>
          </div>
        </div>
      </virtual-scroller>
    </ng-template>
  </div>
</div>
