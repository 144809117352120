import { Injectable } from "@angular/core";
import { EstatisticasLeitura } from "../models/usuario/EstatisticasLeitura";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { StatusService } from "../services/status.service";
import { AppConfig } from "../app.config";
import { ErrorHandlerService } from "../services/errorHandler.service";

const STORE_NAME = "estatisticas";

@Injectable()
export class EstatisticasRepositorio {
  constructor(
    private dbService: NgxIndexedDBService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  async listar(): Promise<EstatisticasLeitura[]> {
    try {
      return await this.dbService
        .getAll(STORE_NAME)
        .toPromise()
        .then((estatisticas: EstatisticasLeitura[]) => estatisticas);
    } catch (err) {
      this.errorHandlerService.handleError(err);
    }
  }

  async buscar(idLei: string): Promise<EstatisticasLeitura> {
    try {
      return await this.dbService
        .getByKey(STORE_NAME, idLei)
        .toPromise()
        .then((estatisticas: EstatisticasLeitura) => estatisticas);
    } catch (err) {
      this.errorHandlerService.handleError(err);
    }
  }

  async salvar(estatisticas: EstatisticasLeitura) {
    try {
      await this.dbService.update(STORE_NAME, estatisticas).toPromise();
    } catch (err) {
      this.errorHandlerService.handleError(err);
    }
  }
}
