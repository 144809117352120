import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { Apontamento } from "../timer.service";
import { StorageHelper } from "../../helpers/storage.helper";
import {
  SignalrService,
  OperacaoSignalR,
  EnumTipoObjeto,
} from "../signalr.service";

@Injectable()
export class UsuarioApontamentosService {
  readonly databaseName = "userdata";
  readonly collectionName = "apontamentos";

  public $recarregarApontamentos: Observable<number>;
  public _recarregarApontamentos = new BehaviorSubject<number>(null);

  constructor(private signalrService: SignalrService) {
    this.$recarregarApontamentos = this._recarregarApontamentos.asObservable();

    signalrService.$Mensagem.subscribe((msg) => this.onMessageReceived(msg));
  }

  private onMessageReceived(msg: OperacaoSignalR): void {
    if (msg && msg.tipoObjeto === EnumTipoObjeto.Apontamentos) {
      this.atualizar(msg.dados, false);
    }
  }

  public fromNuvem(apontamentos: Apontamento[]): Promise<void> {
    return new Promise((onsuccess) => {
      StorageHelper.upsertMany(
        apontamentos,
        this.databaseName,
        this.collectionName,
        false
      )
        .then(() => {
          onsuccess();
        })
        .catch((err) => {
          throw err;
        });
    });
  }

  public atualizar(
    apontamento: Apontamento,
    sync = true
  ): Promise<Apontamento> {
    return new Promise((onsuccess, onerror) => {
      this._recarregarApontamentos.next(Math.random());

      StorageHelper.upsert(apontamento, this.databaseName, this.collectionName)
        .then((apontamento) => {
          if (sync) {
            const mensagem = new OperacaoSignalR();
            mensagem.dados = apontamento;
            this.signalrService.enviarMensagem(
              mensagem,
              EnumTipoObjeto.Apontamentos
            );
          }

          onsuccess(apontamento);
        })
        .catch((err) => {
          onerror(err);
        });
    });
  }

  public buscar(): Promise<Apontamento[]>;
  public buscar(idLei: string): Promise<Apontamento[]>;
  public buscar(
    filtro: (apontamento: Apontamento) => boolean
  ): Promise<Apontamento[]>;

  public buscar(idLei = null, filtro = null): Promise<Apontamento[]> {
    const listarTodos = (): Promise<Apontamento[]> => {
      return new Promise((onsuccess, onerror) => {
        StorageHelper.list<Apontamento>(this.databaseName, this.collectionName)
          .then((apontamentos) => onsuccess(apontamentos))
          .catch((err) => onerror(err));
      });
    };

    const listarPorLei = (): Promise<Apontamento[]> => {
      return new Promise((onsuccess, onerror) => {
        StorageHelper.list<Apontamento>(
          this.databaseName,
          this.collectionName,
          (a) => a.idLei === idLei
        )
          .then((apontamentos) => onsuccess(apontamentos))
          .catch((err) => onerror(err));
      });
    };

    const listarFiltro = (): Promise<Apontamento[]> => {
      return new Promise((onsuccess, onerror) => {
        StorageHelper.list<Apontamento>(
          this.databaseName,
          this.collectionName,
          filtro
        )
          .then((apontamentos) => onsuccess(apontamentos))
          .catch((err) => onerror(err));
      });
    };

    return new Promise((onsuccess, onerror) => {
      let task: Promise<Apontamento[]> = null;

      if (filtro) task = listarTodos();
      else if (idLei) task = listarPorLei();
      else task = listarTodos();

      task
        .then((apontamentos) => {
          onsuccess(apontamentos);
        })
        .catch((err) => {
          onerror(err);
        });
    });
  }
}
