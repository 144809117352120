<div class="barra-progresso-wrapper flex-1">
  <div class="container fundo">
    <div class="progress" [style.width.%]="progresso"></div>
  </div>
  <div class="container texto flex-row center-center">
    <span>{{ progresso ? math.floor(progresso) : 0 }}% Lido</span>
    <span *ngIf="tempoRestante">
      <span>, Restam </span>
      <ng-container *ngIf="segundos && !minutos && !horas; else elseTemplate">
        <span>{{ segundos }}s</span>
      </ng-container>
      <ng-template #elseTemplate>
        <span *ngIf="horas">{{ horas }}h</span>
        <span *ngIf="horas && minutos">, </span>
        <span *ngIf="minutos">{{ minutos }}m</span>
      </ng-template>
    </span>
  </div>
</div>
