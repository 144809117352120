import { Injectable } from "@angular/core";
import { TagConteudo } from "../interfaces/TagConteudo";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable()
export class HoverService {
  public itens: Observable<TagConteudo[]>;
  private itensSubject = new BehaviorSubject<TagConteudo[]>(null);

  constructor() {
    this.itens = this.itensSubject.asObservable();
  }

  get atual(): TagConteudo[] {
    return this.itensSubject.getValue();
  }

  public zerar(): void {
    this.itensSubject.next(null);
  }

  public destacar(itens: TagConteudo[]): void {
    this.itensSubject.next(itens);
  }
}
