<span class="placeholder flex-row center-start">
  <span class="favorito">
    <button mat-icon-button (click)="btnFavorito_click($event)">
      <mat-icon *ngIf="item.favorita">bookmark</mat-icon>
      <mat-icon *ngIf="!item.favorita">bookmark_border</mat-icon>
    </button>
  </span>
  <span class="descricao flex-column flex-1">
    <span class="titulo">{{ item.titulo }}</span>

    <ng-container
      *ngIf="!item.atualizacaoPendente; else elseItemAtualizacaoPendente"
    >
      <span class="data-alteracao" *ngIf="item.dataHoraUltimaModificacao">
        Última alteração em:
        {{ item.dataHoraUltimaModificacao | date : "dd/MM/yyyy" }}
      </span>
      <span class="data-alteracao" *ngIf="!item.dataHoraUltimaModificacao">
        Última alteração indefinida
      </span>
    </ng-container>

    <ng-template #elseItemAtualizacaoPendente>
      <span class="data-alteracao">
        <mat-chip-list>
          <mat-chip class="chip-atualizacao">
            documento em processo de atualização</mat-chip
          >
        </mat-chip-list>
      </span>
    </ng-template>
  </span>
  <span class="info">
    <button mat-icon-button (click)="btnInfo_click($event, item)">
      <mat-icon>info</mat-icon>
    </button></span
  >
  <span class="progresso" style="display: none">
    <app-indicador-leitura
      class="placeholder-indicador-leitura flex-row"
      *ngIf="item.progresso >= 0 || tempoRestante"
      [progresso]="item.progresso"
      [tempoRestante]="tempoRestante"
    ></app-indicador-leitura>
  </span>
</span>
