import { HostListener, Injectable } from "@angular/core";
import { Observable, BehaviorSubject, Subscription, fromEvent } from "rxjs";
import { Tema } from "../models/tema";
import { TemasApp } from "../constants/lista.temas";
import { UsuarioPreferenciasService } from "./data-services/usuario.preferencias.service";
import { map } from "rxjs/operators";

@Injectable()
export class UiService {
  private tema: Tema = null;
  private tema$ = new BehaviorSubject<Tema>(this.tema);

  private isMobile$: Subscription = Subscription.EMPTY;
  private mobile: boolean = window.innerWidth <= 1024;
  private mobile$ = new BehaviorSubject<boolean>(this.mobile);

  private larguraTela: number = window.innerWidth;
  private larguraTela$ = new BehaviorSubject<number>(this.larguraTela);

  private alturaTela: number = window.innerHeight;
  private alturaTela$ = new BehaviorSubject<number>(this.alturaTela);

  private idTema: number;

  constructor(private usuarioPreferenciasService: UsuarioPreferenciasService) {
    this.usuarioPreferenciasService.$Configuracoes.subscribe((config) => {
      if (config && this.idTema !== config.idTema) {
        this.idTema = config.idTema;
        this.updateTema();
      }
    });

    this.isMobile$ = fromEvent(window, "resize")
      .pipe(map(() => window))
      .subscribe((status) => {
        this.mobile = status.innerWidth <= 1024;
        this.larguraTela = status.innerWidth;
        this.alturaTela = status.innerHeight;
        this.updateAlturaTela();
        this.updateMobile();
        this.updateLarguraTela();
      });
  }

  public getMobile(): Observable<boolean> {
    return this.mobile$.asObservable();
  }
  public get isMobile(): boolean {
    return this.mobile$.getValue();
  }

  public getLarguraTela(): Observable<number> {
    return this.larguraTela$.asObservable();
  }

  public getAlturaTela(): Observable<number> {
    return this.alturaTela$.asObservable();
  }

  public getTema(): Observable<Tema> {
    return this.tema$.asObservable();
  }

  private updateTema(): void {
    this.tema$.next(TemasApp[this.idTema]);
  }

  private updateAlturaTela(): void {
    this.alturaTela$.next(this.alturaTela);
  }
  private updateMobile(): void {
    this.mobile$.next(this.mobile);
  }
  private updateLarguraTela(): void {
    this.larguraTela$.next(this.larguraTela);
  }

  public AlterarTema(tema: Tema): void {
    this.tema$.next(tema);
  }
}
