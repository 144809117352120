import { Injectable } from "@angular/core";
import {
  BuscaPanelParameters,
  estadoJanelaBusca,
} from "../components/leitor-content-panelbusca/busca-panel.parameters";
import { Intervalo } from "../components/leitor-content-pagina/pagina.parameters";
import { MatchBuscaTexto } from "../models/MatchBuscaTexto";
import { StorageHelper } from "../helpers/storage.helper";
import { BuscaSalva } from "../models/UserData";
import { Observable, BehaviorSubject, of } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { StringHelper } from "../helpers/string.helper";
import { UsuarioGuiasService } from "./data-services/usuario.guias.service";
import { Guia } from "../models/Guia";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { StatusService } from "./status.service";
import {
  CompressedResult,
  CompressionHelper,
} from "../helpers/compression.helper";
import { BuscaRequest } from "../arguments/busca/BuscaRequest";
import { ResultadoBuscaResponse } from "../arguments/busca/ResultadoBuscaResponse";
import { LoggingService } from "./logging.service";

export class ResultadoMarcacaoItem {
  idItem: string;
  intervalos: Array<Intervalo>;

  constructor() {
    this.intervalos = new Array<Intervalo>();
  }
}

@Injectable()
export class BuscaService {
  readonly databaseName = "buscas";
  readonly collectionName = "buscas";

  public busca: BuscaPanelParameters = null;
  private busca$ = new BehaviorSubject<BuscaPanelParameters>(this.busca);
  private guiaAtiva: Guia;

  constructor(
    public dialog: MatDialog,
    private usuarioGuiasService: UsuarioGuiasService,
    private loggingService: LoggingService,
    private statusService: StatusService,
    private httpClient: HttpClient
  ) {
    usuarioGuiasService.getGuiaAtiva().subscribe((g) => this.guia_subscribe(g));
  }

  private async guia_subscribe(guiaAtual: Guia): Promise<void> {
    const taskname = "carregarBusca";
    if (
      !guiaAtual ||
      (this.guiaAtiva && guiaAtual.idLei === this.guiaAtiva.idLei)
    )
      return;

    this.guiaAtiva = guiaAtual;
    await this.fecharPainelBusca(false);
    const buscaSalva = await this.carregarBuscaGuia(this.guiaAtiva.id);
    let busca = null;
    if (buscaSalva) {
      const busca = BuscaPanelParameters.fromBuscaSalva(buscaSalva);
      if (busca.buscarTodosDocumentos) {
        this.statusService.mostrarMensagemProgresso(
          "Recuperando busca salva",
          taskname
        );
        const resultadoBusca = await this.buscar(busca);
        if (!resultadoBusca.matchResultadoBuscaFoco)
          resultadoBusca.matchResultadoBuscaFoco =
            resultadoBusca.matchsResultadoBusca[0];
        this.busca$.next(resultadoBusca);
        this.statusService.ocultarMensagemProgresso(taskname);
      } else {
        if (!busca.matchResultadoBuscaFoco)
          busca.matchResultadoBuscaFoco = busca.matchsResultadoBusca[0];

        this.busca$.next(busca);
      }
    } else {
      this.busca$.next(busca);
    }
  }

  public async carregarBuscaGuia(idGuia: string): Promise<BuscaSalva> {
    const guias = await StorageHelper.list<BuscaSalva>(
      this.databaseName,
      this.collectionName,
      (b) => b.guiaId === idGuia
    );
    return guias[0];
  }

  public abrirPainelBusca(buscaAberta: BuscaPanelParameters = null): void {
    if (!buscaAberta) {
      buscaAberta = new BuscaPanelParameters();
    }
    this.busca$.next(buscaAberta);
  }

  public async fecharPainelBusca(apagarBusca: boolean) {
    if (apagarBusca) {
      await this.finalizarBusca();
    }

    this.busca$.next(null);
  }

  private async finalizarBusca() {
    if (!this.guiaAtiva) {
      return;
    }

    const buscasArmazenadas = await StorageHelper.list<BuscaSalva>(
      this.databaseName,
      this.collectionName
    );

    // deletar versao antiga da busca
    const indexBuscaDeletar = buscasArmazenadas.findIndex(
      (busca) => busca.guiaId === this.guiaAtiva.id
    );

    if (indexBuscaDeletar !== -1) {
      await StorageHelper.delete(
        this.databaseName,
        this.collectionName,
        buscasArmazenadas[indexBuscaDeletar].id
      );
    }

    const carregarBuscas = await StorageHelper.list<BuscaSalva>(
      this.databaseName,
      this.collectionName
    );

    const indexBuscaAtualizar = carregarBuscas.findIndex(
      (busca) => busca.guiaId === this.usuarioGuiasService.valueGuiaAtiva.id
    );
    if (indexBuscaAtualizar !== -1) {
      StorageHelper.delete(
        this.databaseName,
        this.collectionName,
        carregarBuscas[indexBuscaAtualizar].id
      );
    }
  }

  async buscar(params: BuscaPanelParameters): Promise<BuscaPanelParameters> {
    const taskname = "buscar";

    try {
      params.textoBuscar = StringHelper.RemoverAcentosCaracteresEspeciais(
        params.textoBuscar
      );

      const guiaAtiva = this.guiaAtiva;
      let idLei = guiaAtiva.idLei;

      if (
        params.buscarTodosDocumentos &&
        params.resultadosBuscaWeb &&
        params.resultadosBuscaWeb.resultadoAtual
      ) {
        idLei = params.resultadosBuscaWeb.resultadoAtual.id;
      }
      //#region busca no documentos atual
      if (!params.buscarTodosDocumentos) {
        this.loggingService.LogEvent(
          "Leitor - Buscar documento atual",
          null,
          null
        );
        const buscaRequest = new BuscaRequest();

        buscaRequest.idLei = idLei;
        buscaRequest.texto = params.textoBuscar;

        if (params.provascaiuEmProva.ativo) {
          buscaRequest.buscarMarcacoes = true;
          if (params.provaBuscar) {
            if (params.provaBuscar.instituicao)
              buscaRequest.idInstituicaoMarcacao =
                params.provaBuscar.instituicao.id;
            if (params.provaBuscar.banca)
              buscaRequest.idBancaMarcacao = params.provaBuscar.banca.id;
            if (params.provaBuscar.ano)
              buscaRequest.idAnoMarcacao = params.provaBuscar.ano.id;
            if (params.provaBuscar.tipo)
              buscaRequest.idTipoMarcacao = params.provaBuscar.tipo.id;
            if (params.provaBuscar.cargo)
              buscaRequest.idCargoMarcacao = params.provaBuscar.cargo.id;
          }
        }

        if (params.comentariosComentado.ativo || params.comentarioBuscar) {
          buscaRequest.buscarComentarios = true;
          buscaRequest.textoComentario = params.comentarioBuscar;
        }

        if (params.mnemonicosOpcao.ativo || params.mnemonicoBuscar) {
          buscaRequest.buscarMnemonicos = true;
          buscaRequest.textoMnemonico = params.mnemonicoBuscar;
        }

        buscaRequest.buscarCaneta1 = params.marcacoesCaneta[0].ativo;
        buscaRequest.buscarCaneta2 = params.marcacoesCaneta[1].ativo;
        buscaRequest.buscarCaneta3 = params.marcacoesCaneta[2].ativo;
        buscaRequest.buscarCaneta4 = params.marcacoesCaneta[3].ativo;
        buscaRequest.buscarCaneta5 = params.marcacoesCaneta[4].ativo;

        const url = `${AppConfig.apiEndpoint}/busca/v2`;

        this.statusService.mostrarMensagemProgresso("Buscando...", taskname);

        const result: any = await this.httpClient
          .post(url, buscaRequest)
          .toPromise();

        params.matchsResultadoBusca = new Array<MatchBuscaTexto>();
        params.matchResultadoBuscaFoco = null;

        this.statusService.mostrarMensagemProgresso(
          "Processando resultados...",
          taskname
        );

        const resultadosBusca = <ResultadoBuscaResponse>(
          JSON.parse(CompressionHelper.unzip(result))
        );
        params.estadoJanelaBusca = estadoJanelaBusca.resultadoBuscaSimples;
        params.resultadosBuscaWeb = resultadosBusca;

        if (params.resultadosBuscaWeb.resultadoAtual) {
          params.matchResultadoBuscaFoco = null;
          params.buscaSemResultado =
            resultadosBusca.leisEncontradas.length === 0;

          params.matchsResultadoBusca = [];
          resultadosBusca.nodes.forEach((node) => {
            node.children.forEach((child) => {
              child.ocorrencias.forEach((ocorrencia) => {
                const match = new MatchBuscaTexto();

                match.de = ocorrencia.de;
                match.ate = ocorrencia.ate;
                match.cor = "yellow";
                match.textoItemId = child.id;

                match.idLei = resultadosBusca.resultadoAtual.id;
                match.tituloLei = resultadosBusca.resultadoAtual.titulo;

                params.matchsResultadoBusca.push(match);
              });
            });
          });
          params.matchResultadoBuscaFoco = params.matchsResultadoBusca[0];

          this.busca$.next(params);
          this.salvarBusca(new BuscaSalva(params));
          this.statusService.ocultarMensagemProgresso(taskname);

          return params;
        } else {
          params.buscaSemResultado = true;

          this.busca$.next(params);
          this.salvarBusca(new BuscaSalva(params));
          this.statusService.ocultarMensagemProgresso(taskname);

          return params;
        }
      } else {
        // #region busca todos os documentos

        this.loggingService.LogEvent(
          "Leitor - Buscar todos documentos",
          null,
          null
        );

        const buscaRequest = new BuscaRequest();

        buscaRequest.texto = params.textoBuscar;

        if (params.idPagina) buscaRequest.pagina = params.idPagina;

        if (params.provascaiuEmProva.ativo) {
          buscaRequest.buscarMarcacoes = true;
          if (params.provaBuscar) {
            if (params.provaBuscar.instituicao)
              buscaRequest.idInstituicaoMarcacao =
                params.provaBuscar.instituicao.id;
            if (params.provaBuscar.banca)
              buscaRequest.idBancaMarcacao = params.provaBuscar.banca.id;
            if (params.provaBuscar.ano)
              buscaRequest.idAnoMarcacao = params.provaBuscar.ano.id;
            if (params.provaBuscar.tipo)
              buscaRequest.idTipoMarcacao = params.provaBuscar.tipo.id;
            if (params.provaBuscar.cargo)
              buscaRequest.idCargoMarcacao = params.provaBuscar.cargo.id;
          }
        }

        if (params.comentariosComentado.ativo || params.comentarioBuscar) {
          buscaRequest.buscarComentarios = true;
          buscaRequest.textoComentario = params.comentarioBuscar;
        }

        if (params.mnemonicosOpcao.ativo || params.mnemonicoBuscar) {
          buscaRequest.buscarMnemonicos = true;
          buscaRequest.textoMnemonico = params.mnemonicoBuscar;
        }

        buscaRequest.buscarCaneta1 = params.marcacoesCaneta[0].ativo;
        buscaRequest.buscarCaneta2 = params.marcacoesCaneta[1].ativo;
        buscaRequest.buscarCaneta3 = params.marcacoesCaneta[2].ativo;
        buscaRequest.buscarCaneta4 = params.marcacoesCaneta[3].ativo;
        buscaRequest.buscarCaneta5 = params.marcacoesCaneta[4].ativo;

        const url = `${AppConfig.apiEndpoint}/busca/v2`;

        this.statusService.mostrarMensagemProgresso("Buscando...", taskname);

        const result: any = await this.httpClient
          .post(url, buscaRequest)
          .toPromise();

        params.matchsResultadoBusca = new Array<MatchBuscaTexto>();
        params.matchResultadoBuscaFoco = null;

        this.statusService.mostrarMensagemProgresso(
          "Processando resultados...",
          taskname
        );

        const resultadosBusca = <ResultadoBuscaResponse>(
          JSON.parse(CompressionHelper.unzip(result))
        );

        params.estadoJanelaBusca =
          estadoJanelaBusca.resultadoBuscaAvancadaMinimizado;
        params.resultadosBuscaWeb = resultadosBusca;

        if (params.resultadosBuscaWeb.resultadoAtual) {
          params.matchResultadoBuscaFoco = null;
          params.buscaSemResultado =
            resultadosBusca.leisEncontradas.length === 0;

          params.matchsResultadoBusca = [];
          resultadosBusca.nodes.forEach((node) => {
            node.children.forEach((child) => {
              child.ocorrencias.forEach((ocorrencia) => {
                const match = new MatchBuscaTexto();

                match.de = ocorrencia.de;
                match.ate = ocorrencia.ate;
                match.cor = "yellow";
                match.textoItemId = child.id;

                match.idLei = resultadosBusca.resultadoAtual.id;
                match.tituloLei = resultadosBusca.resultadoAtual.titulo;

                params.matchsResultadoBusca.push(match);
              });
            });
          });

          this.busca$.next(params);
          this.statusService.ocultarMensagemProgresso(taskname);

          return params;
        } else {
          params.buscaSemResultado = true;

          this.busca$.next(params);
          this.statusService.ocultarMensagemProgresso(taskname);

          return params;
        }
      }
    } catch (error) {
      this.statusService.setMessage(error.message);
      this.statusService.ocultarMensagemProgresso(taskname);
    }
  }

  public async salvarBusca(
    buscaSalvar: BuscaSalva = null,
    idGuia: string = null
  ) {
    if (!this.guiaAtiva && !idGuia) {
      return;
    }

    // Salvar status busca ja aberta
    if (!buscaSalvar && !this.busca$.value) return;
    else if (!buscaSalvar) buscaSalvar = new BuscaSalva(this.busca$.value);

    // Vincular busca à guia
    buscaSalvar.guiaId = !idGuia ? this.guiaAtiva.id : idGuia;

    const buscasArmazenadas = await StorageHelper.list<BuscaSalva>(
      this.databaseName,
      this.collectionName
    );

    // deletar versao antiga da busca
    const indexBuscaDelete = buscasArmazenadas.findIndex(
      (busca) => busca.guiaId === buscaSalvar.guiaId
    );
    if (indexBuscaDelete !== -1) {
      await StorageHelper.delete(
        this.databaseName,
        this.collectionName,
        buscasArmazenadas[indexBuscaDelete].id
      );
    }

    // salvar versão nova
    await StorageHelper.upsert(
      buscaSalvar,
      this.databaseName,
      this.collectionName
    );

    const carregarBuscas = await StorageHelper.list<BuscaSalva>(
      this.databaseName,
      this.collectionName
    );

    const indexBuscaAtualizar = carregarBuscas.findIndex(
      (busca) => busca.guiaId === buscaSalvar.guiaId
    );
    if (indexBuscaAtualizar !== -1 && buscasArmazenadas[indexBuscaAtualizar]) {
      StorageHelper.delete(
        this.databaseName,
        this.collectionName,
        buscasArmazenadas[indexBuscaAtualizar].id
      );
    }

    await StorageHelper.upsert(
      buscaSalvar,
      this.databaseName,
      this.collectionName
    );
  }

  updateBusca() {
    this.busca$.next(this.busca);
  }

  getBusca(): Observable<BuscaPanelParameters> {
    return this.busca$.asObservable();
  }
}
