import { DBConfig } from "ngx-indexed-db";

const COMENTARIO_GERENCIADO = "comentario-gereciado-conteudo";
const REFERENCIAS_GERENCIADO = "referencias-gereciada-conteudo";

export function migrationFactory() {
  // Adicionar numero da versão e tabela que precisa ser criada/recriada
  return {
    7: (db, transaction) => {
      if (!db.objectStoreNames.contains(COMENTARIO_GERENCIADO)) {
        db.createObjectStore(COMENTARIO_GERENCIADO, {
          keyPath: "idLei",
          autoIncrement: false,
        }).createIndex("idLei", "idLei", { unique: true });
      }
    },
    8: (db, transaction) => {
      if (!db.objectStoreNames.contains(REFERENCIAS_GERENCIADO)) {
        db.createObjectStore(REFERENCIAS_GERENCIADO, {
          keyPath: "idLei",
          autoIncrement: false,
        }).createIndex("idLei", "idLei", { unique: true });
      }
    },
  };
}

export const dbConfig: DBConfig = {
  name: "svdm",
  version: 8,
  objectStoresMeta: [
    {
      store: "lei-info",
      storeConfig: { keyPath: "id", autoIncrement: false },
      storeSchema: [],
    },
    {
      store: "lei-conteudo",
      storeConfig: { keyPath: "id", autoIncrement: false },
      storeSchema: [],
    },
    {
      store: "lei-download",
      storeConfig: { keyPath: "id", autoIncrement: false },
      storeSchema: [],
    },
    {
      store: "estatisticas",
      storeConfig: { keyPath: "id", autoIncrement: false },
      storeSchema: [],
    },
    {
      store: "datasControle",
      storeConfig: { keyPath: "tipo", autoIncrement: false },
      storeSchema: [],
    },
    {
      store: "errorLog",
      storeConfig: { keyPath: "clientId", autoIncrement: false },
      storeSchema: [],
    },
    {
      store: COMENTARIO_GERENCIADO,
      storeConfig: { keyPath: "idLei", autoIncrement: false },
      storeSchema: [],
    },
    {
      store: REFERENCIAS_GERENCIADO,
      storeConfig: { keyPath: "idLei", autoIncrement: false },
      storeSchema: [],
    },
  ],
  // provide the migration factory to the DBConfig
  migrationFactory,
};
